import { Image, Text, TouchableOpacity, View, Linking } from "react-native";
import Elevations from "react-native-elevation";
import { Icon } from "react-native-eva-icons";
import { useToast } from "react-native-toast-notifications";

import docimg from "../../../assets/doc.png";
import imageimg from "../../../assets/image.png";
import pdfimg from "../../../assets/pdf.png";
import pptimg from "../../../assets/ppt.png";
import txtimg from "../../../assets/text.png";
import xlsimg from "../../../assets/xls.png";
import {APIadminsURL} from "@env";

function DocumentCard({
  name,
  size,
  date,
  filetype = "none",
  url,
  width = "90%",
  maxWidth = 600,
  minWidth,
}) {
  size = formatBytes(size);
  const toast = useToast();
  let docicon, bgcolor;
  if (filetype === "pdf") {
    docicon = pdfimg;
    bgcolor = "#e5252a";
  } else if (filetype === "doc") {
    docicon = docimg;
    bgcolor = "#0263d1";
  } else if (filetype === "ppt") {
    docicon = pptimg;
    bgcolor = "#e03303";
  } else if (filetype === "xls") {
    docicon = xlsimg;
    bgcolor = "#00733b";
  } else if (filetype === "image") {
    docicon = imageimg;
    bgcolor = "#0ac963";
  } else {
    docicon = txtimg;
    bgcolor = "#251d36";
  }
  const openfile = () => {
    if (url) {
      // Linking.openURL(`https://test2.enterpriseesolutions.com/public` + url).catch((err) => {
      //   console.log(err);
      // });
      if (filetype === "pdf") {
        Linking.openURL(`https://test2.enterpriseesolutions.com/public` + url).catch((err) => {
          console.log(err);
        });
      } else {
        Linking.openURL("https://view.officeapps.live.com/op/embed.aspx?src=" + url).catch(
          (err) => {
            console.log(err);
          }
        );
      }
    } else {
      toast.show("document has no path url", {
        type: "danger",
        placement: "bottom",
      });
    }
  };

  const downloadfile = () => {
    if (url) {
      // Linking.openURL(`https://test2.enterpriseesolutions.com/public` + url).catch((err) => {
      //   console.log(err);
      // });
      Linking.openURL(url).catch((err) => {
        console.log(err);
      });
    } else {
      toast.show("document has no path url", {
        type: "danger",
        placement: "bottom",
      });
    }
  };

  return (
    <TouchableOpacity
      activeOpacity={0.6}
      underlayColor="#DDDDDD"
      onPress={() => {
        openfile();
      }}
      style={[
        {
          width,
          maxWidth,
          minWidth,
          height: 80,
          borderRadius: 10,
          backgroundColor: "#fff",
          marginVertical: 15,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginHorizontal: 15,
        },
        Elevations[5],
      ]}>
      <View
        style={{
          flex: 1,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Image source={docicon} style={{ width: 60, height: 60 }} />
      </View>
      <View
        style={{
          flex: 3,
          paddingLeft: 10,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "flex-start",
          flexDirection: "column",
        }}>
        <Text style={{ fontFamily: "Roboto_500Medium", fontSize: 16 }}>{name}</Text>
        <Text style={{ fontFamily: "Roboto_400Regular", fontSize: 12, color: "#888" }}>
          {size}
          {"  "}•{"  "}
          {date}
        </Text>
      </View>
      <TouchableOpacity
        onPress={() => {
          downloadfile();
        }}
        style={{
          flex: 1,
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: bgcolor,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
        }}>
        <Icon name="download" fill="#fff" width={24} height={24} />
      </TouchableOpacity>
    </TouchableOpacity>
  );
}

export default DocumentCard;

function formatBytes(a, b = 2) {
  if (!+a) return "0 Bytes";
  const c = b < 0 ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  }`;
}
