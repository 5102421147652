import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button, Radio, Modal, RadioGroup, Card } from "@ui-kitten/components";
import { useState, useEffect, useRef } from "react";
import {
  Text,
  Dimensions,
  TextInput,
  ScrollView,
  TouchableOpacity,
  View,
  StyleSheet,
} from "react-native";
import { Icon } from "react-native-eva-icons";
import RenderHTML from "react-native-render-html";
import Select, { components } from "react-select";
import CountryFlag from "react-native-country-flag";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import "react-phone-input-2/lib/bootstrap.css";
import NumericInput from "@wwdrew/react-native-numeric-textinput";
import LoadingComp from "../../../components/loading";
import Input from "react-phone-number-input/input";
import {APIadminsURL} from "@env";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
function SubStep1({ nextstep }) {
  const { id } = useParams();
  const [MinMax, setMinMax] = useState("");
  const [subtype, setSubtype] = useState(0);
  const [sigtype, setsigtype] = useState(0);
  const [invtype, setinvtype] = useState(0);
  const [lastName, setlastName] = useState("");
  const width = Dimensions.get("window").width;
  const [InvestorTypeDesc, setInvestorTypeDesc] = useState("");
  const [InvestorTypeTitle, setInvestorTypeTitle] = useState("");
  const ismobile = width < 768;
  const [visible, setVisible] = useState(false);
  const [supMaxInvest, setSupMaxInvest] = useState(false);
  const [min_invest, setmin_invest] = useState(0);
  const [max_invest, setmax_invest] = useState(0);
  const [options, setOptions] = useState([]);
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(true);
  const [signatoryPhone, setSignatoryPhone] = useState("");
  const [requiredName, setRequiredName] = useState(false);
  const [valuePhone, setValuePhone] = useState();
  const [defaultValueCountry, setDefaultValueCountry] = useState({});
  const [defaultValueCountryOfCitizenship, setDefaultValueCountryOfCitizenship] = useState({});

  useEffect(() => {
    if (sigtype == 0) {
      setRequiredName(true);
    } else {
      setRequiredName(true);
    }
  }, [sigtype]);
  const CustomOption = ({ children, ...props }) => {
    return (
      <>
        <components.Option {...props}>
          <View
            style={{
              width: "100%",
              alignSelf: "center",
              flexDirection: "row",
              borderRadius: 10,
              marginVertical: 5,
            }}>
            <Text style={{ fontFamily: "Roboto_700Bold" }}> {children}</Text>
          </View>
        </components.Option>
      </>
    );
  };
  const myPhoneInput = ({ value, children, ...props }) => {
    console.log(props);
    console.log(children);
    return (
      <>
        <TextInput
          style={{
            width: "100%",
            height: 40,
            borderColor: "#bdbdbd",
            borderRadius: 5,
            borderWidth: 1,
            marginTop: 10,
            paddingHorizontal: 10,
          }}
        />
      </>
    );
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      width: "100%",
      height: 40,
      borderColor: "#bdbdbd",
      borderRadius: 5,
      borderWidth: 1,
      marginTop: 10,
      paddingHorizontal: 10,
      backgroundColor: "#00000000",
      fontFamily: "system-ui",
    }),
  };
  const { investment } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      commitment: "",
      legal_first_name: "",
      legal_type: 0,
      legal_middle_name: "",
      city: "",
      company_name: "",
      citizenship_country: "",
      email: "",
      investor_type: 0,
      job_title: "",
      legal_last_name: "",
      phone: "",
      zipcode: "",
      state: "",
      street_line1: "",
      street_line2: "",
      country: "",
      signatory_type: 0,
      organization_name: "",
    },
  });
  const onSubmit = (data) => {
    console.log(data);
    data.commitment = data.commitment.toString().replace(/\D/g, "");
    data["id"] = id;
    data["step"] = 1;
    console.log(data);
    let type = 1;
    if (data.legal_type == 1) {
      type = 2;
    }
    data.legal_type = type;
    let investorType = 1;
    if (data.investor_type == 1) {
      investorType = 2;
    } else if (data.investor_type == 2) {
      investorType = 3;
    }
    data.investor_type = investorType;

    let signatoryType = 1;
    if (data.signatory_type == 0) {
      signatoryType = 1;
    } else if (data.signatory_type == 1) {
      signatoryType = 2;
    }

    data.signatory_type = signatoryType;
    AsyncStorage.getItem("token").then((res) => {
      axios
        .post(`https://test2.enterpriseesolutions.com/public/api/subscription/update`, data, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((res) => {
          if (res.data.status == 200) {
            nextstep();
          }
          console.log(res.data);
        });
    });
  };
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      if (res !== null) {
        axios
          .get(`https://test2.enterpriseesolutions.com/public/api/countries`, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            //res.data.forEach(myFunction);
            console.log(res.data);
            setOptions(res.data);
          });
      }
    });
  }, []);
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      if (res !== null) {
        axios
          .get(`https://test2.enterpriseesolutions.com/public/api/investment/` + investment, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            //res.data.forEach(myFunction);
            console.log(res.data);
            setmin_invest(res.data.investment.min_invest);
            setmax_invest(res.data.investment.max_invest);
          });
      }
    });
  }, []);
  useEffect(() => {
    AsyncStorage.getItem("token").then((token) => {
      if (token !== null) {
        axios
          .get(`https://test2.enterpriseesolutions.com/public/api/subscription/` + id, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resSub) => {
            if (resSub.data.commitment == 0) {
              let data = { test: "test" };
              axios
                .post(`https://test2.enterpriseesolutions.com/public/api/subscription/contact`, data, {
                  headers: { Authorization: `Bearer ${token}` },
                })
                .then((res) => {
                  //res.data.forEach(myFunction);
                  console.log(res.data);
                  defaultValFormContact(res);
                });
            } else {
              //alert("ok");
              let type = 0;
              if (resSub.data.legal_type == 2) {
                type = 1;
              }
              setSubtype(type);
              setsigtype(resSub.data.signatory_type == 2 ? 1 : 0);
              defaultValFormSub(resSub);
            }
            setLoading(false);
            //res.data.forEach(myFunction);commitment
            console.log(resSub.data);
          });
      }
    });
  }, [reset]);
  function defaultValFormContact(res) {
    setlastName(res.data.contact.legal_last_name);
    let type = 0;
    if (res.data.contact.type == 2) {
      type = 1;
    }
    let investorType = 0;
    if (res.data.contact.investor_type == 2) {
      investorType = 1;
    } else if (res.data.contact.investor_type == 3) {
      investorType = 2;
    }
    let defaults = {
      legal_first_name: res.data.contact.first_name ? res.data.contact.first_name : "",
      legal_type: type,
      legal_middle_name: res.data.contact.middle ? res.data.contact.middle : "",
      city: res.data.contact.city ? res.data.contact.city : "",
      company_name: res.data.contact.company_name ? res.data.contact.company_name : "",
      citizenship_country: res.data.contact.country ? res.data.contact.country : "",
      email: res.data.contact.email ? res.data.contact.email : "",
      investor_type: investorType,
      job_title: res.data.contact.job_title ? res.data.contact.job_title : "",
      legal_last_name: res.data.contact.last_name ? res.data.contact.last_name : "",
      phone: res.data.contact.phone ? res.data.contact.phone : "",
      zipcode: res.data.contact.postal_code ? res.data.contact.postal_code : "",
      state: res.data.contact.province ? res.data.contact.province : "",
      street_line1: res.data.contact.street_line1 ? res.data.contact.street_line1 : "",
      street_line2: res.data.contact.street_line2 ? res.data.contact.street_line2 : "",
      signatory_type: 0,
    };
    setPhone(res.data.contact.phone ? res.data.contact.phone : "");
    setValuePhone(res.data.contact.phone ? res.data.contact.phone : "");
    setSubtype(type);

    reset(defaults);
  }
  function defaultValFormSub(res) {
    // alert(res.data.organization_name)
    setlastName(res.data.legal_last_name);
    let type = 0;
    if (res.data.legal_type == 2) {
      type = 1;
    }
    let investorType = 0;
    if (res.data.investor_type == 2) {
      investorType = 1;
    } else if (res.data.investor_type == 3) {
      investorType = 2;
    }
    let defaults = {
      signatory_type: res.data.signatory_type == 2 ? 1 : 0,
      organization_name: res.data.organization_name ? res.data.organization_name : "",
      commitment: res.data.commitment,
      legal_first_name: res.data.legal_first_name ? res.data.legal_first_name : "",
      legal_type: type,
      legal_middle_name: res.data.legal_middle_name ? res.data.legal_middle_name : "",
      city: res.data.city ? res.data.city : "",
      company_name: res.data.company_name ? res.data.company_name : "",
      citizenship_country: res.data.citizenship_country ? res.data.citizenship_country : "",
      email: res.data.email ? res.data.email : "",
      investor_type: investorType,
      job_title: res.data.job_title ? res.data.job_title : "",
      legal_last_name: res.data.legal_last_name ? res.data.legal_last_name : "",
      phone: res.data.phone ? res.data.phone : "",
      zipcode: res.data.zipcode ? res.data.zipcode : "",
      state: res.data.state ? res.data.state : "",
      street_line1: res.data.street_line1 ? res.data.street_line1 : "",
      street_line2: res.data.street_line2 ? res.data.street_line2 : "",
      tax_id: res.data.tax_id ? res.data.tax_id : "",
      signatory_entity_title: res.data.signatory_entity_title
        ? res.data.signatory_entity_title
        : "",
      signatory_entity_name: res.data.signatory_entity_name ? res.data.signatory_entity_name : "",
      signatory_phone: res.data.signatory_phone ? res.data.signatory_phone : "",
      signatory_email: res.data.signatory_email ? res.data.signatory_email : "",
      signatory_title: res.data.signatory_title ? res.data.signatory_title : "",
      signatory_last_name: res.data.signatory_last_name ? res.data.signatory_last_name : "",
      signatory_middle_name: res.data.signatory_middle_name ? res.data.signatory_middle_name : "",
      signatory_first_name: res.data.signatory_first_name ? res.data.signatory_first_name : "",
    };
    //alert(res.data.phone ? res.data.phone : "")
    setSignatoryPhone(res.data.signatory_phone ? res.data.signatory_phone : "");
    setValuePhone(res.data.phone ? res.data.phone : "");
    setDefaultValueCountry({
      label: res.data.countryName ? res.data.countryName : "",
      value: res.data.country ? res.data.country : "",
    });
    setDefaultValueCountryOfCitizenship({
      label: res.data.citizenship_countryName ? res.data.citizenship_countryName : "",
      value: res.data.citizenship_countryName ? res.data.citizenship_countryName : "",
    });
    reset(defaults);
  }
  const [reqc, setreqc] = useState(0);

  function PhoneNumberFun(val) {
    //alert(isValidPhoneNumber(val));
    if (isValidPhoneNumber(val)) {
      //let msg =
      return true;

      //setMinMax
    }
    return false;
  }
  function testFu(val) {
    val = val.toString().replace(/\D/g, "");

    if (
      parseInt(val) > (max_invest == 0 ? val : max_invest) ||
      parseInt(val) < (min_invest == 0 ? val : min_invest)
    ) {
      //let msg =
      if(parseInt(val) > (max_invest == 0 ? val : max_invest)){
        setSupMaxInvest(true);
      }else{
        setSupMaxInvest(false);
      }
      return false;

      //setMinMax
    }
    return true;
  }
  function InvestorTypeFun(e) {
    if (e == 1) {
      setInvestorTypeTitle("Accredited investor ");
      setInvestorTypeDesc(`<p style="text-align: justify;margin-bottom: 0px;"><strong>Accredited investors&nbsp;</strong>is defined in section 1.1 of <span style="color: rgb(97, 189, 109);"><a style="text-decoration: none;color:#8dc641;text-decoration-color:#8dc641;" href="https://ca.practicallaw.thomsonreuters.com/0-574-9502?originationContext=document&transitionType=DocumentItem&contextData=(sc.Default)&ppcid=e8c7cb52a8c142aaa9a4a2c23afecc34&firstPage=true" rel="noopener noreferrer" target="_blank">National Instrument 45-106 Prospectus Exemptions</a></span>. Among others, accredited investors could be:</p>
      <ol style="list-style-type: lower-roman;">
          <li style="text-align: justify">An individual whose net income before taxes exceeded $200,000 in each of the two most recent calendar years or whose net income before taxes combined with that of a spouse exceeded $300,000 in each of the two most recent calendar years and who, in either case, reasonably expects to exceed that net income level in the current calendar year.&nbsp;</li>
          <li style="text-align: justify">An individual who beneficially owns financial assets having an aggregate realizable value that, before taxes but net of any related liabilities, exceeds $5,000,000.</li>
      </ol>`);
    } else if (e == 2) {
      setInvestorTypeTitle("Friends , family and business associates");
      setInvestorTypeDesc(`<p style="text-align: justify;margin-bottom: 0px;">Family, Friend and Business Associate Investors are defined in sections 2.6 and 2.6.1 of <a  style="text-decoration: none;color:#8dc641;text-decoration-color:#8dc641;" href="https://ca.practicallaw.thomsonreuters.com/0-574-9502?originationContext=document&transitionType=DocumentItem&contextData=(sc.Default)&ppcid=e8c7cb52a8c142aaa9a4a2c23afecc34" rel="noopener noreferrer" target="_blank"><span  style="color:#8dc641">National Instrument 45-106 Prospectus Exemptions</span></a>. Investors within this category could be:&nbsp;</p>
      <ol style="list-style-type: lower-roman;">
          <li style="color: rgb(0, 0, 0);text-align: justify">A <a style="text-decoration: none;" href="https://ca.practicallaw.thomsonreuters.com/0-562-3673?originationContext=document&transitionType=DocumentItem&contextData=(sc.Default)&ppcid=8607165a845b4334919f697901417bd9" rel="noopener noreferrer" target="_blank"><strong><span style="color: rgb(0, 0, 0);">director</span></strong></a>, <a style="text-decoration: none;" href="https://ca.practicallaw.thomsonreuters.com/6-571-2510?originationContext=document&transitionType=DocumentItem&contextData=(sc.Default)&ppcid=8607165a845b4334919f697901417bd9" rel="noopener noreferrer" target="_blank"><strong><span style="color: rgb(0, 0, 0);">executive officer</span></strong></a> or <a style="text-decoration: none;" href="https://ca.practicallaw.thomsonreuters.com/1-570-3867?originationContext=document&transitionType=DocumentItem&contextData=(sc.Default)&ppcid=8607165a845b4334919f697901417bd9" rel="noopener noreferrer" target="_blank"><strong><span style="color: rgb(0, 0, 0);">control person</span></strong></a> of the issuer or of an affiliate of the issuer. </li>
          <li style="text-align: justify">A spouse, parent, grandparent, brother, sister, child or grandchild of a director, executive officer or control person of the issuer, or of an affiliate of the issuer.</li>
          <li style="text-align: justify">&nbsp;A parent, grandparent, brother, sister, child or grandchild of the spouse of a director, executive officer or control person of the issuer or of an <a style="text-decoration: none;" href="https://ca.practicallaw.thomsonreuters.com/4-561-8146?originationContext=document&transitionType=DocumentItem&contextData=(sc.Default)&ppcid=8607165a845b4334919f697901417bd9" rel="noopener noreferrer" target="_blank"><strong><span style="color: rgb(0, 0, 0);">affiliate </span></strong></a>of the issuer.&nbsp;</li>
          <li style="text-align: justify">A close personal friend of a director, executive officer or control person of the issuer, or of an affiliate of the issuer.</li>
          <li style="text-align: justify">&nbsp;A close business associate of a director, executive officer or control person of the issuer, or of an affiliate of the issuer.</li>
          <li style="text-align: justify">&nbsp;A founder of the issuer or a spouse, parent, grandparent, brother, sister, child, grandchild, close personal friend or close business associate of a founder of the issuer.</li>
          <li style="text-align: justify">&nbsp;A parent, grandparent, brother, sister, child or grandchild of a spouse of a <a style="text-decoration: none;" href="https://ca.practicallaw.thomsonreuters.com/6-574-9405?originationContext=document&transitionType=DocumentItem&contextData=(sc.Default)&ppcid=8607165a845b4334919f697901417bd9" rel="noopener noreferrer" target="_blank"><strong><span style="color: rgb(0, 0, 0);">founder</span></strong></a>&nbsp;of the issuer.</li>
          <li style="text-align: justify">&nbsp;A person of which a majority of the voting securities are beneficially owned by, or a majority of the directors are, persons described in the first seven bullets.</li>
          <li style="text-align: justify">&nbsp;A <a style="text-decoration: none;" href="https://ca.practicallaw.thomsonreuters.com/2-575-5375?originationContext=document&transitionType=DocumentItem&contextData=(sc.Default)&ppcid=8607165a845b4334919f697901417bd9" rel="noopener noreferrer" target="_blank"><strong><span style="color: rgb(0, 0, 0);">trust</span></strong></a>&nbsp;or estate of which all of the beneficiaries or a majority of the trustees or executors are persons described in the first seven bullets.</li>
      </ol>`);
    } else if (e == 3) {
      setInvestorTypeTitle("Others");
      setInvestorTypeDesc("");
    }
    setVisible(true);
    //window.scrollTo(0, 0);
    //setInvestorTypeDesc("fdsfdfs")
  }
  if (loading) return <LoadingComp />;
  return (
    <>
      <View
        style={{
          backgroundColor: "#fff",
          borderRadius: 15,
          padding: 25,
          zIndex: 9,
        }}>
        <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 22, color: "#8dc641" }}>
          Investment Amount
        </Text>
        <Text
          style={{
            fontFamily: "Roboto_700Bold",
            fontSize: 16,
            color: "#333",
            marginTop: 30,
            paddingLeft: 10,
            paddingBottom: 10,
          }}>
          Requested commitment <Text style={{ color: "#df0101" }}>*</Text>
        </Text>
        <Controller
          control={control}
          rules={{
            required: true,
            validate: (value) => testFu(value),
          }}
          name="commitment"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              value={Convertfun(value)}
              /* value={reqc} */
              onChangeText={onChange}
              onBlur={onBlur}
              type="currency"
              locale="en-US"
              currency="USD"
              style={{
                width: "50%",
                height: 40,
                borderColor: "#bdbdbd",
                borderRadius: 5,
                borderWidth: 1,
                marginTop: 10,
                paddingHorizontal: 10,
                marginLeft: 10,
              }}
            />
          )}
        />
        {MinMax != "" ? <Text style={styles.inputerror}>{MinMax}</Text> : null}
        {errors.commitment?.type === "required" ? (
          <Text style={styles.inputerror}>This field is required</Text>
        ) : errors.commitment?.type === "validate" ? (
          <Text style={styles.inputerror}>
            {supMaxInvest == true ? "" : "S7 Equity accepts this subscription for a commitment amount more than the amount you designated."}
            {supMaxInvest == false ? "" : "S7 Equity accepts this subscription for a commitment amount less than the amount you designated."}
          </Text>
        ) : null}

       
        <Text
          style={{
            fontFamily: "Roboto_500Medium",
            fontSize: 12,
            color: "#bdbdbd",
            marginTop: 10,
            paddingLeft: 10,
          }}>
            Note:{" "}
          {min_invest == 0 && max_invest == 0 ? "" : "The investment amount must be"}
          {min_invest == 0 ? "" : " at least " + Convertfun(min_invest)}
          {min_invest == 0 || max_invest == 0 ? "" : " and"}
          {max_invest == 0 ? "" : " at most " + Convertfun(max_invest)}
        </Text>
      </View>

      <View
        style={{
          backgroundColor: "#fff",
          borderRadius: 15,
          padding: 25,
          zIndex: 9,
          marginTop: 10,
        }}>
        <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 22, color: "#8dc641" }}>
          Create Account
        </Text>
        <Text
          style={{
            fontFamily: "Roboto_700Bold",
            fontSize: 16,
            color: "#333",
            marginTop: 30,
            paddingBottom: 10,
          }}>
          What type of legal entity is the subscriber
        </Text>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name="legal_type"
          render={({ field: { onChange, onBlur, value } }) => (
            <RadioGroup
              style={{
                fontFamily: "Roboto_500Medium",
                fontSize: 16,
                flexDirection: "row",
                paddingLeft: 10,
              }}
              selectedIndex={value}
              /* value={reqc} */
              onChange={(e) => {
                onChange(e); // method from hook form register
                setSubtype(e); // your method
              }}
              onBlur={onBlur}>
              <Radio status="success">Individual</Radio>
              <Radio status="success" style={{ paddingLeft: 20 }}>
                Organization
              </Radio>
            </RadioGroup>
          )}
        />

        {subtype === 0 && (
          <>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <View
                style={{ flexDirection: "column", marginRight: 10, width: "30%", paddingLeft: 10 }}>
                <Text
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: ismobile ? 11 : 14,
                    color: "#333",
                    marginTop: 10,
                  }}>
                  First Name <Text style={{ color: "#df0101" }}>*</Text>
                </Text>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name="legal_first_name"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      value={value}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      style={{
                        width: "100%",
                        height: 40,
                        borderColor: "#bdbdbd",
                        borderRadius: 5,
                        borderWidth: 1,
                        marginTop: 10,
                        paddingHorizontal: 10,
                      }}
                    />
                  )}
                />
                {errors.legal_first_name?.type === "required" ? (
                  <Text style={styles.inputerror}>This first name is required</Text>
                ) : errors.legal_last_name?.type === "required" ? (
                  <Text style={styles.inputerror}>This last name is required</Text>
                ) : null}
              </View>
              <View style={{ flexDirection: "column", marginRight: 10, width: "30%" }}>
                <Text
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: ismobile ? 11 : 14,
                    color: "#333",
                    marginTop: 10,
                  }}>
                  Middle Name
                </Text>
                <Controller
                  control={control}
                  rules={{}}
                  name="legal_middle_name"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      style={{
                        width: "100%",
                        height: 40,
                        borderColor: "#bdbdbd",
                        borderRadius: 5,
                        borderWidth: 1,
                        marginTop: 10,
                        paddingHorizontal: 10,
                      }}
                    />
                  )}
                />
              </View>
              <View style={{ flexDirection: "column", marginRight: 10, width: "30%" }}>
                <Text
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: ismobile ? 11 : 14,
                    color: "#333",
                    marginTop: 10,
                  }}>
                  Last Name <Text style={{ color: "#df0101" }}>*</Text>
                </Text>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name="legal_last_name"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      style={{
                        width: "100%",
                        height: 40,
                        borderColor: "#bdbdbd",
                        borderRadius: 5,
                        borderWidth: 1,
                        marginTop: 10,
                        paddingHorizontal: 10,
                      }}
                    />
                  )}
                />
              </View>
            </View>
            <View style={{ flexDirection: "row", marginTop: 10 }}>
              <View
                style={{ flexDirection: "column", marginRight: 20, width: "40%", paddingLeft: 10 }}>
                <Text
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: ismobile ? 11 : 14,
                    color: "#333",
                    marginTop: 10,
                  }}>
                  Email <Text style={{ color: "#df0101" }}>*</Text>
                </Text>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: "Invalid Email Format",
                    },
                  }}
                  name="email"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      style={{
                        width: "100%",
                        height: 40,
                        borderColor: "#bdbdbd",
                        borderRadius: 5,
                        borderWidth: 1,
                        marginTop: 10,
                        paddingHorizontal: 10,
                      }}
                    />
                  )}
                />
                {errors.email?.type === "required" ? (
                  <Text style={styles.inputerror}>This field is required</Text>
                ) : errors.email?.type === "pattern" ? (
                  <Text style={styles.inputerror}>Invalid Email Format</Text>
                ) : null}
              </View>
              <View style={{ flexDirection: "column", marginRight: 10, width: "40%" }}>
                <Text
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: ismobile ? 11 : 14,
                    color: "#333",
                    marginTop: 10,
                  }}>
                  Phone Number <Text style={{ color: "#df0101" }}>*</Text>
                </Text>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                    validate: (value) => PhoneNumberFun(value),
                  }}
                  name="phone"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <PhoneInput
                    smartCaret={false}
                    /* displayInitialValueAsLocalNumber */
                      style={{
                        width: "100%",
                        height: 40,
                        border: "solid",
                        borderColor: "#bdbdbd",
                        borderRadius: 5,
                        borderWidth: 1,
                        marginTop: 10,
                        paddingHorizontal: 10,
                      }}
                      placeholder="Enter phone number"
                      onChange={(val) => {
                        onChange(val);
                        setValuePhone(val);
                      }}
                      /*  inputComponent={myPhoneInput} */
                      value={valuePhone}
                    />
                  )}
                />

                {errors.phone?.type === "required" ? (
                  <Text style={styles.inputerror}>This field is required</Text>
                ) : errors.phone?.type === "validate" ? (
                  <Text style={styles.inputerror}>Invalid phone number</Text>
                ) : null}
              </View>
            </View>
            <View style={{ flexDirection: "row", marginTop: 10 }}>
              <View
                style={{ flexDirection: "column", marginRight: 10, width: "40%", paddingLeft: 10 }}>
                <Text
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: ismobile ? 11 : 14,
                    color: "#333",
                    marginTop: 10,
                  }}>
                  Country of citizenship
                </Text>
                <Controller
                  control={control}
                  rules={{}}
                  name="citizenship_country"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      value={defaultValueCountryOfCitizenship}
                      className="basic-single"
                      classNamePrefix="select"
                      onChange={(val) => {
                        onChange(val.value);
                        setDefaultValueCountryOfCitizenship(val);
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      styles={customStyles}
                      name="color"
                      components={{ Option: CustomOption }}
                      options={options}
                    />
                  )}
                />
              </View>
            </View>
            <View style={{ flexDirection: "row", marginTop: 10 }}>
              <View
                style={{ flexDirection: "column", marginRight: 10, width: "40%", paddingLeft: 10 }}>
                <Text
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: ismobile ? 11 : 14,
                    color: "#333",
                    marginTop: 10,
                  }}>
                  Street (line 1)
                </Text>
                <Controller
                  control={control}
                  rules={{}}
                  name="street_line1"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      style={{
                        width: "100%",
                        height: 40,
                        borderColor: "#bdbdbd",
                        borderRadius: 5,
                        borderWidth: 1,
                        marginTop: 10,
                        paddingHorizontal: 10,
                      }}
                    />
                  )}
                />
              </View>
              <View style={{ flexDirection: "column", marginRight: 10, width: "40%" }}>
                <Text
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: ismobile ? 11 : 14,
                    color: "#333",
                    marginTop: 10,
                  }}>
                  Street (line 2)
                </Text>
                <Controller
                  control={control}
                  rules={{}}
                  name="street_line2"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      style={{
                        width: "100%",
                        height: 40,
                        borderColor: "#bdbdbd",
                        borderRadius: 5,
                        borderWidth: 1,
                        marginTop: 10,
                        paddingHorizontal: 10,
                      }}
                    />
                  )}
                />
              </View>
            </View>
            <View style={{ flexDirection: "row", marginTop: 10 }}>
              <View
                style={{ flexDirection: "column", marginRight: 10, width: "40%", paddingLeft: 10 }}>
                <Text
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: ismobile ? 11 : 14,
                    color: "#333",
                    marginTop: 10,
                  }}>
                  City
                </Text>
                <Controller
                  control={control}
                  rules={{}}
                  name="city"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      style={{
                        width: "100%",
                        height: 40,
                        borderColor: "#bdbdbd",
                        borderRadius: 5,
                        borderWidth: 1,
                        marginTop: 10,
                        paddingHorizontal: 10,
                      }}
                    />
                  )}
                />
              </View>
              <View style={{ flexDirection: "column", marginRight: 10, width: "40%" }}>
                <Text
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: ismobile ? 11 : 14,
                    color: "#333",
                    marginTop: 10,
                  }}>
                  State/Province
                </Text>
                <Controller
                  control={control}
                  rules={{}}
                  name="state"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      style={{
                        width: "100%",
                        height: 40,
                        borderColor: "#bdbdbd",
                        borderRadius: 5,
                        borderWidth: 1,
                        marginTop: 10,
                        paddingHorizontal: 10,
                      }}
                    />
                  )}
                />
              </View>
            </View>
            <View style={{ flexDirection: "row", marginTop: 10 }}>
              <View
                style={{ flexDirection: "column", marginRight: 10, width: "40%", paddingLeft: 10 }}>
                <Text
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: ismobile ? 11 : 14,
                    color: "#333",
                    marginTop: 10,
                  }}>
                  Postal Code
                </Text>
                <Controller
                  control={control}
                  rules={{}}
                  name="zipcode"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      style={{
                        width: "100%",
                        height: 40,
                        borderColor: "#bdbdbd",
                        borderRadius: 5,
                        borderWidth: 1,
                        marginTop: 10,
                        paddingHorizontal: 10,
                      }}
                    />
                  )}
                />
              </View>
              <View style={{ flexDirection: "column", marginRight: 10, width: "40%" }}>
                <Text
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: ismobile ? 11 : 14,
                    color: "#333",
                    marginTop: 10,
                  }}>
                  Country
                </Text>
                <Controller
                  control={control}
                  rules={{}}
                  name="country"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      value={defaultValueCountry}
                      className="basic-single"
                      classNamePrefix="select"
                      onChange={(val) => {
                        onChange(val.value);
                        setDefaultValueCountry(val);
                      }}
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                      styles={customStyles}
                      name="color"
                      components={{ Option: CustomOption }}
                      options={options}
                    />
                  )}
                />
              </View>
            </View>
          </>
        )}
        {subtype === 1 && (
          <>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <View
                style={{ flexDirection: "column", marginRight: 10, width: "45%", paddingLeft: 10 }}>
                <Text
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: ismobile ? 11 : 14,
                    color: "#333",
                    marginTop: 10,
                  }}>
                  Organization Name <Text style={{ color: "#df0101" }}>*</Text>
                </Text>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name="organization_name"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      value={value}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      style={{
                        width: "100%",
                        height: 40,
                        borderColor: "#bdbdbd",
                        borderRadius: 5,
                        borderWidth: 1,
                        marginTop: 10,
                        paddingHorizontal: 10,
                      }}
                    />
                  )}
                />
                {errors.organization_name?.type === "required" ? (
                  <Text style={styles.inputerror}>This field is required</Text>
                ) : null}
              </View>
              <View style={{ flexDirection: "column", marginRight: 10, width: "45%" }}>
                <Text
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: ismobile ? 11 : 14,
                    color: "#333",
                    marginTop: 10,
                  }}>
                  Corporation Number <Text style={{ color: "#df0101" }}>*</Text>
                </Text>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name="tax_id"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      value={value}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      style={{
                        width: "100%",
                        height: 40,
                        borderColor: "#bdbdbd",
                        borderRadius: 5,
                        borderWidth: 1,
                        marginTop: 10,
                        paddingHorizontal: 10,
                      }}
                    />
                  )}
                />
                {errors.tax_id?.type === "required" ? (
                  <Text style={styles.inputerror}>This field is required</Text>
                ) : null}
              </View>
            </View>
          </>
        )}
      </View>
      {subtype === 1 && (
        <View
          style={{
            backgroundColor: "#fff",
            borderRadius: 15,
            padding: 25,
            zIndex: 9,
            marginTop: 20,
          }}>
          <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 22, color: "#8dc641" }}>
            Signatory
          </Text>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            name="signatory_type"
            render={({ field: { onChange, onBlur, value } }) => (
              <RadioGroup
                style={{
                  fontSize: 16,
                  flexDirection: ismobile ? "column" : "row",
                  marginTop: 20,
                  paddingLeft: 10,
                }}
                selectedIndex={value}
                /* value={reqc} */
                onChange={(e) => {
                  onChange(e); // method from hook form register
                  setsigtype(e); // your method
                }}
                onBlur={onBlur}>
                <Radio status="success">Individual</Radio>
                <Radio status="success" style={{ paddingLeft: ismobile ? 0 : 20 }}>
                  Legal Entity
                </Radio>
              </RadioGroup>
            )}
          />

          {sigtype === 0 && (
            <>
              <View style={{ flexDirection: "row", marginTop: 20 }}>
                <View
                  style={{
                    flexDirection: "column",
                    marginRight: 10,
                    width: "30%",
                    paddingLeft: 10,
                  }}>
                  <Text
                    style={{
                      fontFamily: "Roboto_500Medium",
                      fontSize: ismobile ? 11 : 14,
                      color: "#333",
                      marginTop: 10,
                    }}>
                    First Name <Text style={{ color: "#df0101" }}>*</Text>
                  </Text>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    name="signatory_first_name"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        value={value}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        style={{
                          width: "100%",
                          height: 40,
                          borderColor: "#bdbdbd",
                          borderRadius: 5,
                          borderWidth: 1,
                          marginTop: 10,
                          paddingHorizontal: 10,
                        }}
                      />
                    )}
                  />
                  {errors.signatory_first_name?.type === "required" ? (
                    <Text style={styles.inputerror}>This first name is required</Text>
                  ) : errors.signatory_last_name?.type === "required" ? (
                    <Text style={styles.inputerror}>This last name is required</Text>
                  ) : null}
                </View>
                <View style={{ flexDirection: "column", marginRight: 10, width: "30%" }}>
                  <Text
                    style={{
                      fontFamily: "Roboto_500Medium",
                      fontSize: ismobile ? 11 : 14,
                      color: "#333",
                      marginTop: 10,
                    }}>
                    Middle Name
                  </Text>
                  <Controller
                    control={control}
                    rules={{}}
                    name="signatory_middle_name"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        style={{
                          width: "100%",
                          height: 40,
                          borderColor: "#bdbdbd",
                          borderRadius: 5,
                          borderWidth: 1,
                          marginTop: 10,
                          paddingHorizontal: 10,
                        }}
                      />
                    )}
                  />
                </View>
                <View style={{ flexDirection: "column", marginRight: 10, width: "30%" }}>
                  <Text
                    style={{
                      fontFamily: "Roboto_500Medium",
                      fontSize: ismobile ? 11 : 14,
                      color: "#333",
                      marginTop: 10,
                    }}>
                    Last Name <Text style={{ color: "#df0101" }}>*</Text>
                  </Text>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    name="signatory_last_name"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        style={{
                          width: "100%",
                          height: 40,
                          borderColor: "#bdbdbd",
                          borderRadius: 5,
                          borderWidth: 1,
                          marginTop: 10,
                          paddingHorizontal: 10,
                        }}
                      />
                    )}
                  />
                </View>
              </View>
              <View
                style={{
                  flexDirection: "column",
                  marginRight: 10,
                  width: "40%",
                  paddingLeft: 10,
                  marginTop: 10,
                }}>
                <Text
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: ismobile ? 11 : 14,
                    color: "#333",
                    marginTop: 10,
                  }}>
                  Title <Text style={{ color: "#df0101" }}>*</Text>
                </Text>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name="signatory_title"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      style={{
                        width: "100%",
                        height: 40,
                        borderColor: "#bdbdbd",
                        borderRadius: 5,
                        borderWidth: 1,
                        marginTop: 10,
                        paddingHorizontal: 10,
                      }}
                    />
                  )}
                />
                {errors.signatory_title?.type === "required" ? (
                  <Text style={styles.inputerror}>This field is required</Text>
                ) : null}
              </View>
              <View style={{ flexDirection: "row", marginTop: 10 }}>
                <View style={{ flexDirection: "column", width: "45%", paddingLeft: 10 }}>
                  <Text
                    style={{
                      fontFamily: "Roboto_500Medium",
                      fontSize: ismobile ? 11 : 14,
                      color: "#333",
                      marginTop: 10,
                    }}>
                    Email Address <Text style={{ color: "#df0101" }}>*</Text>
                  </Text>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: "Invalid Email Format",
                      },
                    }}
                    name="signatory_email"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        style={{
                          width: "100%",
                          height: 40,
                          borderColor: "#bdbdbd",
                          borderRadius: 5,
                          borderWidth: 1,
                          marginTop: 10,
                          paddingHorizontal: 10,
                        }}
                      />
                    )}
                  />
                  {errors.signatory_email?.type === "required" ? (
                    <Text style={styles.inputerror}>This field is required</Text>
                  ) : errors.signatory_email?.type === "pattern" ? (
                    <Text style={styles.inputerror}>Invalid Email Format</Text>
                  ) : null}
                </View>
                <View style={{ flexDirection: "column", width: "45%", paddingLeft: 20 }}>
                  <Text
                    style={{
                      fontFamily: "Roboto_500Medium",
                      fontSize: ismobile ? 11 : 14,
                      color: "#333",
                      marginTop: 10,
                    }}>
                    Phone Number <Text style={{ color: "#df0101" }}>*</Text>
                  </Text>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                      validate: (value) => PhoneNumberFun(value),
                    }}
                    name="signatory_phone"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <PhoneInput
                      smartCaret={false}
                        style={{
                          width: "100%",
                          height: 40,
                          border: "solid",
                          borderColor: "#bdbdbd",
                          borderRadius: 5,
                          borderWidth: 1,
                          marginTop: 10,
                          paddingHorizontal: 10,
                        }}
                        placeholder="Enter phone number"
                        onChange={(val) => {
                          setSignatoryPhone(val);
                          onChange(val);
                        }}
                        value={signatoryPhone}
                        /*  inputComponent={myPhoneInput} */
                      />
                    )}
                  />
                  {errors.signatory_phone?.type === "required" ? (
                    <Text style={styles.inputerror}>This field is required</Text>
                  ) : errors.signatory_phone?.type === "validate" ? (
                    <Text style={styles.inputerror}>Invalid phone number</Text>
                  ) : null}
                </View>
              </View>
            </>
          )}
          {sigtype === 1 && (
            <>
              <View
                style={{
                  flexDirection: "column",
                  marginRight: 10,
                  width: "40%",
                  paddingLeft: 10,
                  paddingTop: 20,
                }}>
                <Text
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: ismobile ? 11 : 14,
                    color: "#333",
                    marginTop: 10,
                  }}>
                  Entity Name <Text style={{ color: "#df0101" }}>*</Text>
                </Text>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name="signatory_entity_name"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      value={value}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      style={{
                        width: "100%",
                        height: 40,
                        borderColor: "#bdbdbd",
                        borderRadius: 5,
                        borderWidth: 1,
                        marginTop: 10,
                        paddingHorizontal: 10,
                      }}
                    />
                  )}
                />
                {errors.signatory_entity_name?.type === "required" ? (
                  <Text style={styles.inputerror}>This field is required</Text>
                ) : null}
              </View>
              <View
                style={{ flexDirection: "column", marginRight: 10, width: "40%", paddingLeft: 10 }}>
                <Text
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: ismobile ? 11 : 14,
                    color: "#333",
                    marginTop: 10,
                  }}>
                  Entity Title 
                </Text>
                <Controller
                  control={control}
                  rules={{
                  
                  }}
                  name="signatory_entity_title"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      value={value}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      style={{
                        width: "100%",
                        height: 40,
                        borderColor: "#bdbdbd",
                        borderRadius: 5,
                        borderWidth: 1,
                        marginTop: 10,
                        paddingHorizontal: 10,
                      }}
                    />
                  )}
                />
                {errors.signatory_entity_title?.type === "required" ? (
                  <Text style={styles.inputerror}>This field is required</Text>
                ) : null}
              </View>
              <Text
                style={{
                  fontFamily: "Roboto_700Bold",
                  fontSize: 16,
                  color: "#333",
                  marginTop: 30,
                  paddingBottom: 10,
                  paddingLeft: 10,
                }}>
                Contact Person :
              </Text>
              <View style={{ flexDirection: "row", paddingLeft: 10 }}>
                <View style={{ flexDirection: "column", marginRight: 10, width: "30%" }}>
                  <Text
                    style={{
                      fontFamily: "Roboto_500Medium",
                      fontSize: ismobile ? 11 : 14,
                      color: "#333",
                      marginTop: 10,
                    }}>
                    First Name <Text style={{ color: "#df0101" }}>*</Text>
                  </Text>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    name="signatory_first_name"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        value={value}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        style={{
                          width: "100%",
                          height: 40,
                          borderColor: "#bdbdbd",
                          borderRadius: 5,
                          borderWidth: 1,
                          marginTop: 10,
                          paddingHorizontal: 10,
                        }}
                      />
                    )}
                  />
                  {errors.signatory_first_name?.type === "required" ? (
                    <Text style={styles.inputerror}>This first name is required</Text>
                  ) : errors.signatory_last_name?.type === "required" ? (
                    <Text style={styles.inputerror}>This last name is required</Text>
                  ) : null}
                </View>
                <View style={{ flexDirection: "column", marginRight: 10, width: "30%" }}>
                  <Text
                    style={{
                      fontFamily: "Roboto_500Medium",
                      fontSize: ismobile ? 11 : 14,
                      color: "#333",
                      marginTop: 10,
                    }}>
                    Middle Name
                  </Text>
                  <Controller
                    control={control}
                    rules={{}}
                    name="signatory_middle_name"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        style={{
                          width: "100%",
                          height: 40,
                          borderColor: "#bdbdbd",
                          borderRadius: 5,
                          borderWidth: 1,
                          marginTop: 10,
                          paddingHorizontal: 10,
                        }}
                      />
                    )}
                  />
                </View>
                <View style={{ flexDirection: "column", marginRight: 10, width: "30%" }}>
                  <Text
                    style={{
                      fontFamily: "Roboto_500Medium",
                      fontSize: ismobile ? 11 : 14,
                      color: "#333",
                      marginTop: 10,
                    }}>
                    Last Name <Text style={{ color: "#df0101" }}>*</Text>
                  </Text>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    name="signatory_last_name"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        style={{
                          width: "100%",
                          height: 40,
                          borderColor: "#bdbdbd",
                          borderRadius: 5,
                          borderWidth: 1,
                          marginTop: 10,
                          paddingHorizontal: 10,
                        }}
                      />
                    )}
                  />
                </View>
              </View>
              <View
                style={{ flexDirection: "column", marginRight: 10, width: "40%", paddingLeft: 10 }}>
                <Text
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: ismobile ? 11 : 14,
                    color: "#333",
                    marginTop: 10,
                  }}>
                  Title <Text style={{ color: "#df0101" }}>*</Text>
                </Text>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name="signatory_title"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      value={value}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      style={{
                        width: "100%",
                        height: 40,
                        borderColor: "#bdbdbd",
                        borderRadius: 5,
                        borderWidth: 1,
                        marginTop: 10,
                        paddingHorizontal: 10,
                      }}
                    />
                  )}
                />
                {errors.signatory_title?.type === "required" ? (
                  <Text style={styles.inputerror}>This field is required</Text>
                ) : null}
              </View>
              <View style={{ flexDirection: "row", paddingLeft: 10 }}>
                <View style={{ flexDirection: "column", marginRight: 10, width: "45%" }}>
                  <Text
                    style={{
                      fontFamily: "Roboto_500Medium",
                      fontSize: ismobile ? 11 : 14,
                      color: "#333",
                      marginTop: 10,
                    }}>
                    Email Address <Text style={{ color: "#df0101" }}>*</Text>
                  </Text>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: "Invalid Email Format",
                      },
                    }}
                    name="signatory_email"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        value={value}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        style={{
                          width: "100%",
                          height: 40,
                          borderColor: "#bdbdbd",
                          borderRadius: 5,
                          borderWidth: 1,
                          marginTop: 10,
                          paddingHorizontal: 10,
                        }}
                      />
                    )}
                  />
                  {errors.signatory_email?.type === "required" ? (
                    <Text style={styles.inputerror}>This field is required</Text>
                  ) : errors.signatory_email?.type === "pattern" ? (
                    <Text style={styles.inputerror}>Invalid Email Format</Text>
                  ) : null}
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    marginRight: 10,
                    width: "45%",
                    paddingLeft: 20,
                  }}>
                  <Text
                    style={{
                      fontFamily: "Roboto_500Medium",
                      fontSize: ismobile ? 11 : 14,
                      color: "#333",
                      marginTop: 10,
                    }}>
                    Phone Number <Text style={{ color: "#df0101" }}>*</Text>
                  </Text>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                      validate: (value) => PhoneNumberFun(value),
                    }}
                    name="signatory_phone"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <PhoneInput
                      smartCaret={false}
                        style={{
                          width: "100%",
                          height: 40,
                          border: "solid",
                          borderColor: "#bdbdbd",
                          borderRadius: 5,
                          borderWidth: 1,
                          marginTop: 10,
                          paddingHorizontal: 10,
                        }}
                        placeholder="Enter phone number"
                        onChange={(val) => {
                          setSignatoryPhone(val);
                          onChange(val);
                        }}
                        value={signatoryPhone}
                        /*  inputComponent={myPhoneInput} */
                      />
                    )}
                  />
                  {errors.signatory_phone?.type === "required" ? (
                    <Text style={styles.inputerror}>This field is required</Text>
                  ) : errors.signatory_phone?.type === "validate" ? (
                    <Text style={styles.inputerror}>Invalid phone number</Text>
                  ) : null}
                </View>
              </View>
            </>
          )}
        </View>
      )}
      <View
        style={{
          backgroundColor: "#fff",
          borderRadius: 15,
          padding: 25,
          zIndex: 9,
          marginTop: 10,
        }}>
        <Text
          style={{
            fontFamily: "Roboto_700Bold",
            fontSize: 20,
            color: "#8dc641",
            paddingBottom: 20,
          }}>
          Investor Type
        </Text>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name="investor_type"
          render={({ field: { onChange, onBlur, value } }) => (
            <RadioGroup
              style={{ paddingLeft: 10 }}
              selectedIndex={value}
              /* value={reqc} */
              onChange={(e) => {
                onChange(e); // method from hook form register
                setinvtype(e); // your method
              }}
              onBlur={onBlur}>
              <Radio status="success">
                Accredited investor{" "}
                <Text
                  onPress={() => {
                    InvestorTypeFun(1);
                  }}>
                  <Icon name="question-mark-circle" fill="#6c757d" width={16} height={16} />
                </Text>
              </Radio>
              <Radio status="success">
                Friends, family and business associate{" "}
                <Text
                  onPress={() => {
                    InvestorTypeFun(2);
                  }}>
                  <Icon name="question-mark-circle" fill="#6c757d" width={16} height={16} />
                </Text>
              </Radio>
              <Radio status="success">
                Others{" "}
                <Text
                  onPress={() => {
                    InvestorTypeFun(3);
                  }}>
                  <Icon name="question-mark-circle" fill="#6c757d" width={16} height={16} />
                </Text>
              </Radio>
            </RadioGroup>
          )}
        />
      </View>
      <View
        style={{
          width: "100%",
          alignSelf: "center",
          flexDirection: "row",
          justifyContent: "flex-end",
          borderRadius: 10,
          marginVertical: 20,
        }}>
        <Button
          /* onPress={() => {
            nextstep();
          }} */
          onPress={handleSubmit(onSubmit)}
          status="success"
          accessoryRight=<Icon name="arrow-forward-outline" fill="#fff" width={6} height={6} />>
          Next
        </Button>
      </View>
      <Modal
        style={{
          width: ismobile ? "100%" : "700px",
          maxWidth: 800,
          position: "absolute",
          top: "63%",
        }}
        visible={visible}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => setVisible(false)}>
        <Card disabled={false}  style={{
            maxWidth: 690,
            minWidth: 290,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            position: "fixed",
            left: ismobile ? "0%" : "50%",
            transform: ismobile ? "translate(-0%,-50%)" : "translate(-50%,-50%)",
            top: "50%",
          }}>
            <View style={{flexDirection:'row', flexWrap:'wrap'}}>
    
          <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 20, color: "#8dc641",marginVertical: 10,flex:0.9 }}>
            {InvestorTypeTitle}
          </Text>
          <View style={{
              
              flex:0.1
            }}>
                  <TouchableOpacity onPress={() => setVisible(false)} size={"small"} appearance="ghost" style={{width:10,alignSelf: 'flex-end'}}> <Icon name="close-outline" width={20} height={20} style={{top:0}} fill="#aaa" />
</TouchableOpacity>
                  </View>
    </View>
           
    <View style={{
           marginLeft:15,
            }}>
          <RenderHTML
            source={{ html: `${InvestorTypeDesc}` }}
            style={{
              fontFamily: "Roboto_700Bold",
              fontSize: "15px",
              textAlign: "justify",
              color: "#4f5050",
            
            }}
          />
</View>
          <View
            style={{
              width: "100%",
              alignSelf: "center",
              flexDirection: "row",
              justifyContent: "flex-end",
              borderRadius: 10,
              marginVertical: 20,
            }}>
            <Button
              onPress={() => setVisible(false)}
              style={{ marginRight: 10, backgroundColor: "#8dc641", borderColor: "#8dc641" }}>
              OK
            </Button>
          </View>
        </Card>
      </Modal>
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#f3f4f6",
    alignItems: "center",
    justifyContent: "center",
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  inputerror: {
    fontFamily: "Roboto_400Regular",
    fontSize: 12,
    color: "#f00",
    paddingLeft: 10,
    paddingTop: 10,
  },
});
export default SubStep1;
function currency(num) {
  return num.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
}

function Convertfun(params) {
  //return params;
  return "$" + formatNumber(params);
}

function formatNumber(n) {
  // format number 1000000 to 1,234,567
  return n
    .toString()
    .replace(/\D/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
