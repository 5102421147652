import "react-native-gesture-handler";
import * as eva from "@eva-design/eva";
import { ApplicationProvider } from "@ui-kitten/components";
import { Text } from "react-native";
import { ToastProvider } from "react-native-toast-notifications";

import CustomCC from "./pages/custom";
import CustomRouting from "./pages/customRouting";
import Test from "./pages/test";
import Test2 from "./pages/test2";
// eslint-disable-next-line no-unused-vars, import/order, prettier/prettier
import { default as theme } from "./theme.json";
// eslint-disable-next-line no-unused-vars, import/order, prettier/prettier
import { useFonts,Roboto_100Thin, Roboto_100Thin_Italic, Roboto_300Light, Roboto_300Light_Italic, Roboto_400Regular, Roboto_400Regular_Italic, Roboto_500Medium, Roboto_500Medium_Italic, Roboto_700Bold, Roboto_700Bold_Italic, Roboto_900Black, Roboto_900Black_Italic } from '@expo-google-fonts/roboto'

export default function App() {
  const routes = { "/": CustomCC, "/test1": Test, "/test2": Test2 };
  const { error } = useFonts({
    Roboto_300Light,
    Roboto_400Regular,
    Roboto_500Medium,
    Roboto_700Bold,
    Roboto_900Black,
    Roboto_300Light_Italic,
  });
  if (error) {
    return <Text>an error occured</Text>;
  }
  return (
    <ApplicationProvider {...eva} theme={{ ...eva.light, ...theme }}>
      <ToastProvider>
        <CustomRouting routes={routes} />
      </ToastProvider>
    </ApplicationProvider>
  );
}
