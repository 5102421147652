import { Dimensions, Text, TouchableOpacity, View ,Image} from "react-native";
import { Icon } from "react-native-eva-icons";
import { useNavigate } from "react-router-native";
import {APIadminsURL} from "@env";
function CardSubscription({
  id,
  name,
  equity_goal,
  min_invest,
  max_invest,
  closing_date,
  street_name,
  city,
  country,
  sponsor,
  ROI,
  hold_time,
}) {
  const navigate = useNavigate();
  function currency(num) {
    if (num)
      return num.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      });
  }
  function getFormatedStringFromDays(numberOfDays) {
    const years = Math.floor(numberOfDays / 365);
    const months = Math.floor((numberOfDays % 365) / 30);
    const days = Math.floor((numberOfDays % 365) % 30);
  
    const yearsDisplay = years > 0 ? years + (years === 1 ? " year, " : " years, ") : "";
    const monthsDisplay = months > 0 ? months + (months === 1 ? " month, " : " months, ") : "";
    const daysDisplay = days > 0 ? days + (days === 1 ? " day" : " days") : "";
    const res = yearsDisplay + monthsDisplay + daysDisplay;
    return res.replace(/, $/, "");
  }
  const width = Dimensions.get("window").width;
  const ismobile = width < 768;
  return (
    <TouchableOpacity
      onPress={() => {
        navigate("/investments/" + id);
      }}
      style={{
        width: "100%",
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        borderRadius: 15,
        backgroundColor: "#fff",
        paddingBottom: 15,
        marginBottom: 10,
      }}>
      <View
        style={{
          width: "100%",
          justifyContent: "center",
          flexDirection: "row",
        }}>
        <View
          style={{
            paddingHorizontal: 15,
            paddingVertical: 10,
            backgroundColor: "#333",
            width: "100%",
            borderTopRightRadius: 15,
            borderTopLeftRadius: 15,
          }}>
          <Text
            style={{
              fontFamily: "Roboto_700Bold",
              color: "#fff",
              fontSize: 26,
            }}>
            {name}
          </Text>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}>
            <Icon name="pin-outline" width={20} height={20} fill="#fff" />
            <Text style={{ fontFamily: "Roboto_500Medium", color: "#fff", marginLeft: 5 }}>
              {street_name ? street_name + ", " : ""}
              
              {city ? city + ", " : ""}
             
              {country ? country : ""}
            </Text>
          </View>

          {(sponsor?.length>0) && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "left",
                paddingTop:15

              }}>
              {sponsor?.map((spon, key) => {
                  return ([
                    <Image
                      key={key}
                      style={{ height: "1.3em" , width: "2.7em" }}
                      source={{
                        uri: `https://test2.enterpriseesolutions.com/public/img/sponsors/${spon.logo}`,
                        
                      }}
                    />,
                    <Text style={{ fontFamily: "Roboto_500Medium", fontSize: 18 , paddingRight: "10px", paddingLeft: "10px",color: "#fff"}}>{spon.name}</Text>
                    
                  ]
                  );
                })}
            </View>
            )}

        </View>
      </View>
      <View
        style={{
          width: "90%",
          height: 110,
          marginTop: 10,
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "column",
          alignSelf: "center",
        }}>
        <View
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            height: 25,
          }}>
          <Text style={{ fontFamily: "Roboto_500Medium", color: "#a2a2a2" }}>Target Investment ROI:</Text>
          <Text style={{ fontFamily: "Roboto_700Bold",color: "#333", fontSize: 16 }}>{ROI ? ROI + "%" : "TBD"}</Text>
        </View>
        <View style={{ width: "100%", borderColor: "#e9e9e9", borderBottomWidth: 1 }} />
        <View
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            marginTop: 5,
            height: 25,
          }}>
          <Text style={{ fontFamily: "Roboto_500Medium", color: "#a2a2a2" }}>Funding Target:</Text>
          <Text style={{ fontFamily: "Roboto_700Bold",color: "#333", fontSize: 16 }}>{equity_goal ? currency(equity_goal) : "TBD"}</Text>
        </View>
        <View style={{ width: "100%", borderColor: "#e9e9e9", borderBottomWidth: 1 }} />
        <View
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            marginTop: 5,
            height: 25,
          }}>
          <Text style={{ fontFamily: "Roboto_500Medium", color: "#a2a2a2" }}>Targeted Holding Period:</Text>
          <Text style={{ fontFamily: "Roboto_700Bold",color: "#333", fontSize: 16 }}>
          {hold_time ? (hold_time == 1 ? hold_time + " month" : hold_time + " months") : "TBD"}
          </Text>
        </View>
        <View style={{ width: "100%", borderColor: "#e9e9e9", borderBottomWidth: 1 }} />
        <View
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            marginTop: 5,
            height: 25,
          }}>
          <Text style={{ fontFamily: "Roboto_500Medium", color: "#a2a2a2" }}>Investment Due Date:</Text>
          <Text style={{ fontFamily: "Roboto_700Bold",color: "#333", fontSize: 16 }}>{closing_date ? closing_date  : "TBD"}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}

export default CardSubscription;
