import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { Button, Modal, Card as KittenCard  } from "@ui-kitten/components";
import { Text, Image, ScrollView,Dimensions, StyleSheet, TextInput, View } from "react-native";
import { DataTable } from "react-native-paper";
import { useState, useEffect, useRef } from "react";
import { Modalize } from "react-native-modalize";
import Iframe from "react-iframe";
import { WebView } from "react-native-web-webview";

import RenderHtml from "react-native-render-html";
import { useNavigate } from "react-router-native";
import {APIadminsURL} from "@env";
function Emails() {
  /* const data = [
    { Sent: "Jul 26", Subject: "stuff about things", Investment: "investment 1" },
    { Sent: "Sep 16", Subject: "book of revelations", Investment: "investment 1" },
    { Sent: "Jun 22", Subject: "life is pain", Investment: "investment 2" },
    { Sent: "Dec 3", Subject: "eds eds eds", Investment: "investment 3" },
    { Sent: "Oct 5", Subject: "Done in 26.24s", Investment: "investment 1" },
    { Sent: "Nov 17", Subject: "manmade horrors beyond my comprehension", Investment: "investment 3" },
  ]; */
  const [showinfomodal, setShowinfomodal] = useState(false);
  const [data, setData] = useState([]);
  const [thisDate, setDate] = useState(" ");
  const [sender, setSender] = useState(" ");
  const [recipient, setRecipient] = useState(" ");
  const [subject, setSubject] = useState(" ");

  const [thisSource, setSource] = useState("<p>r</p>");
  const navigate = useNavigate();
  const modalizeRef = useRef(null);
  const width = Dimensions.get("window").width;
  const ismobile = width < 768;
  function openTosModal(e, mydate, Sender, recip, Subj) {
    setSource(e);
    setDate(mydate);
    setSender(Sender);
    setRecipient(recip);
    setSubject(Subj);
     setShowinfomodal(true);
  }
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      if (res !== null) {
        axios
          .get(`https://test2.enterpriseesolutions.com/public/api/myemails`, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            res.data.forEach(myFunction);
            //console.log(res.data);
          })
          .catch((res) => {
            if (res.response.status === 401) {
              navigate("/login");
            }
          });
      }
    });
  }, []);
  function closeTosModal() {
    modalizeRef.current?.close();
  }

  function myFunction(item, index, arr) {
    setData((data) => [
      ...data,
      {
        date: item.date,
        date2: item.date2,
        recipient: item.recipient,
        sender: item.sender,
        text: item.text,
        Sent: item.created_at,
        Subject: item.subject,
        Investment: item.investment,
      },
    ]);
  }

  return (
    <View
      style={{
        marginTop: 100,
        height: "100%",
        width: "100%",
      }}>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title style={{ flex: 1 }}>Sent</DataTable.Title>
          <DataTable.Title style={{ flex: 2 }}>Subject</DataTable.Title>
          <DataTable.Title style={{ flex: 1 }}>Investment</DataTable.Title>
        </DataTable.Header>
        {data.map((row, index) => {
          return (
            <DataTable.Row
              key={index}
              onPress={() => {
                openTosModal(row.text, row.date2, row.sender, row.recipient, row.Subject);
              }}>
              <DataTable.Cell style={{ flex: 1 }}>{row.date}</DataTable.Cell>
              <DataTable.Cell style={{ flex: 2 }}>{row.Subject}</DataTable.Cell>
              <DataTable.Cell style={{ flex: 1 }}>{row.Investment}</DataTable.Cell>
            </DataTable.Row>
          );
        })}
      </DataTable>
      <Modal
        style={{ width: ismobile ? "80%" : 300, maxWidth: 500,}}
        visible={showinfomodal}
        backdropStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        onBackdropPress={() => setShowinfomodal(false)}
    >
        
        <KittenCard
          style={{
            width: ismobile ? "80%" : 700, 
            maxWidth: 800,
            display: "flex",
            justifyContent: "center",
          
            flexDirection: "column",
            position: "fixed",
            left: ismobile ? "0%" : "50%",
            transform: ismobile ? "translate(-0%,-50%)" : "translate(-50%,-50%)",
            top: "50%",
          }}>
          <ScrollView
            style={{
              paddingVertical: 10,
              paddingHorizontal: 10,
              borderColor: "#eee",
              borderWidth: 1,
              margin: 20,
            }}>
            <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 20 }}>Email details</Text>

            <View
              style={{
                borderBottomColor: "black",
                borderBottomWidth: StyleSheet.hairlineWidth,
                marginVertical: 5,
              }}
            />
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  margin: 8,
                }}>
                <Text style={{ fontFamily: "Roboto_400Regular", margin: 5 }}>Date </Text>
                <Text style={{ fontFamily: "Roboto_400Regular", margin: 5 }}>From </Text>
                <Text style={{ fontFamily: "Roboto_400Regular", margin: 5 }}>To </Text>
                <Text style={{ fontFamily: "Roboto_400Regular", margin: 5 }}>Subject </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  margin: 8,
                }}>
                <Text style={{ fontFamily: "Roboto_400Regular", margin: 5 }}>{thisDate}</Text>
                <Text style={{ fontFamily: "Roboto_400Regular", margin: 5 }}>{sender}</Text>
                <Text style={{ fontFamily: "Roboto_400Regular", margin: 5 }}>{recipient}</Text>
                <Text style={{ fontFamily: "Roboto_400Regular", margin: 5 }}>{subject}</Text>
              </View>
            </View>
            <View
              style={{
                borderBottomColor: "black",
                borderBottomWidth: StyleSheet.hairlineWidth,
                marginVertical: 5,
              }}
            />
            <View style={{ paddingHorizontal: 5 }}>
              <WebView
                style={{ width: "100%", height: 350 }}
                originWhitelist={["*"]}
                source={{ html: `${thisSource}` }}
              />{" "}
            </View>
          </ScrollView>
     
        <Button
          style={{ width: "80%", alignSelf: "center", marginBottom: 20 }}
          status="success"
          onPress={() => setShowinfomodal(false)}>
          DISMISS
        </Button>
        </KittenCard>
      </Modal>
    </View>
  );
}

export default Emails;
