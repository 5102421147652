import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useEffect, useState } from "react";
import { ScrollView, View, Text, Dimensions } from "react-native";
import Grid from "react-native-grid-component";
import { useNavigate } from "react-router-native";
import {APIadminsURL} from "@env";
import Card from "../../../components/card";
import LoadingComp from "../../../components/loading";
//TODO : add resoponsive navbar (use Dimensions)

function Investments() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isError, setIsError] = useState(false);
  let cols;
  const windowWidth = Dimensions.get("window").width;
  if (windowWidth < 500) {
    cols = 1;
  } else if (windowWidth >= 500 && windowWidth < 1150) {
    cols = 2;
  } else {
    cols = 3;
  }
  const _renderItem = (data, i) => (
    <View style={{ flex: 1, margin: 1, marginBottom: 20 }}>
      <Card
        id={data.id}
        name={data.name}
        status={data.status}
        equity_goal={data.display_raise == 0 ? null : data.equity_goal}
        min_invest={data.min_invest}
        max_invest={data.max_invest}
        closing_date={data.display_close == 0 ? null : data.closing_date}
        photo={data.cover}
        key={i}
        city={data.city}
        country={data.country}
        street_name={data.street_name}
        tags={data.tags}
        types={data.types}
        onClick={ViewInvest}
        login={1}
      />
    </View>
  );
  function ViewInvest(id) {
    //alert('ok');
    navigate("/investments/" + id);
    //setVisible(true);
  }
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      if (res !== null) {
        axios
          .get(`https://test2.enterpriseesolutions.com/public/api/myinvestments`, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            setData(res.data);
            console.log("myinvestments",res.data);
          })
          .catch((res) => {
            if (res.response.status === 401) {
              navigate("/login");
            }
            if (res.response.status === 404) {
              setIsError(true);
            }
          });
      } else {
        navigate("/login");
      }
    });
  }, []);
  if (isError) return <NoDocs />;
  if (!data) return <LoadingComp />;

  return (
    <ScrollView
      style={{ backgroundColor: "#f3f3f3" }}
      contentContainerStyle={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 100,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
        marginHorizontal: "auto",
      }}>
      {/* {data.map((inv, key) => {
        return (
          <View
            style={{
              padding: 10,
              maxWidth: 400,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              width: "100%",
              margin: 0,
              height: 520,
              marginHorizontal: "auto",
            }}>
            <Card
              id={inv.id}
              name={inv.name}
              status={inv.status}
              equity_goal={inv.equity_goal}
              min_invest={inv.min_invest}
              max_invest={inv.max_invest}
              closing_date={inv.closing_date}
              photo={inv.cover}
              key={key}
              city={inv.city}
              country={inv.country}
              street_name={inv.street_name}
              tags={inv.tags}
              types={inv.types}
            />
          </View>
        );
      })} */}
      <Grid style={{ flex: 1 }} renderItem={_renderItem} data={data} numColumns={cols}  />
      <View style={{ height: 100, width: 1 }} />
    </ScrollView>
  );
}

export default Investments;

function NoDocs() {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        backgroundColor: "#f7f8fa",
      }}>
      <Text style={{ fontSize: 20, fontFamily: "Roboto_700Bold", color: "#909398" }}>
        You have no active offerings
      </Text>
      <Text style={{ fontSize: 20, fontFamily: "Roboto_700Bold", color: "#909398", marginTop: 5 }}>
        If your account manager has invited you to any active offerings, they will appear here
      </Text>
    </View>
  );
}
