import { Button, Text, CheckBox } from "@ui-kitten/components";
import { useState, useEffect } from "react";
import axios from "axios";
import { Image, StyleSheet, TextInput, BackHandler, View } from "react-native";
import { useNavigate } from "react-router-native";
import { useToast } from "react-native-toast-notifications";
import {APIadminsURL} from "@env";
import logo from "../../../assets/s7logo_crop.png";

function ForgetPassword() {
  const [email, setemail] = useState();
  const navigate = useNavigate();
  const toast = useToast();
  function handlepress() {
    const data = { email };
  
    axios.post(`https://test2.enterpriseesolutions.com/api/InvestorResetLink`, data)
    .then((res) => {
      console.log(res);
       if (res.status === 200 && res.data.Result[0]==="statusEmail") {
        toast.show(res.data.Result[1], {
          type: "success",
          placement: "bottom",
        });
      }else {
        toast.show("Something went wrong: "+res.data.Result[1], {
          type: "warning",
          placement: "bottom",
        });
      }
    })
      .catch((res) => {
        console.log(res);
        toast.show(JSON.parse(res.response.data)['email'][0], {
          type: "danger",
          placement: "bottom",
        });
      
      })
  }

  useEffect(() => {
    const backAction = () => {
      navigate(-1);
      return true;
    };

    const backHandler = BackHandler.addEventListener("hardwareBackPress", backAction);

    return () => backHandler.remove();
  }, []);

  return (
    <>
      <View style={styles.container}>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "80%",
            backgroundColor: "#fff",
            maxWidth: 500,
            height: 480,
            maxHeight: 480,
            borderRadius: 20,
            boxShadow: "1px 2px 9px #ddd",
            elevation: 10,
            shadowColor: "#555",
          }}>
          <Image source={logo} style={{ width: "100%", height: 100, resizeMode: "contain" }} />
          <View style={{ display: "flex", alignItems: "flex-start", width: "80%", marginTop: 20 }}>
            <Text style={{ fontFamily: "Roboto_700Bold", marginBottom: 10, fontSize:18 }}>Forgot your password?</Text>
            <Text>enter the email address associated with your account</Text>
          </View>
          <View style={{ width: "80%", marginTop: 10 }}>
            <Text style={{ fontFamily: "Roboto_400Regular", marginBottom: 4 }}>Email:</Text>
            <TextInput
              value={email}
              onChangeText={(e) => {
                setemail(e);
              }}
              style={{
                marginBottom: 20,
                backgroundColor: "#f7f9fc",
                borderColor: "#e4e9f2",
                borderWidth: 1,
                height: 40,
                borderRadius: 4,
                padding: 7,
                width: "100%",
              }}
            />
          </View>
          <Button
            onPress={() => {
              handlepress();
            }}
            style={{ width: 200, marginTop: 12, marginBottom: 1 }}
            status="success">
            {(evaProps) => (
              <Text style={{ fontFamily: "Roboto_500Medium", color: "white" }}>
                Reset My Password
              </Text>
            )}
          </Button>
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#f3f4f6",
    alignItems: "center",
    justifyContent: "center",
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
});

export default ForgetPassword;
