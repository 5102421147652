import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useState, useEffect } from "react";
import { ScrollView, View, Text, Dimensions, Image } from "react-native";
import Grid from "react-native-grid-component";
import { useNavigate } from "react-router-native";
import img from "../../../assets/mt_folder.png";
import DocumentCard from "../../../components/documents/doccard";
import DocListItem from "../../../components/documents/doclistitem";
import DocSection from "../../../components/documents/docsection";
import DocumentsView from "../../../components/documents/docview";
import LoadingComp from "../../../components/loading";
import {APIadminsURL} from "@env";

function Documents() {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [folders, setFolders] = useState([]);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  let cols;
  const windowWidth = Dimensions.get("window").width;
  const width = Dimensions.get("window").width;
  const ismobile = width < 900;
  if (windowWidth < 500) {
    cols = 1;
  } else if (windowWidth >= 500 && windowWidth < 1200) {
    cols = 2;
  } else {
    cols = 3;
  }

  // console.log(windowWidth);
  // const _renderItem = (data, i) => (
  //   <View style={{ flex: 1, margin: 1 }}>
  //     <DocumentCard
  //       key={i}
  //       name={data.display_name ?? data.name}
  //       filetype={data.extension}
  //       size={data.size}
  //       date={data.created_at.substring(0, 10)}
  //       url={data.path}
  //       width="90%"
  //       maxWidth={400}
  //     />
  //   </View>
  // );
  useEffect(() => {
    console.log("categories:", categories);
    console.log("folders:", folders);
  }, [categories, folders]);
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      if (res !== null) {
        axios
          .get(`https://test2.enterpriseesolutions.com/public/api/mydocuments`, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            setData([...data, res.data]);
            console.log(res.data);
          })
          .catch((res) => {
            if (res.response.status === 401) {
              navigate("/login");
            }
            if (res.response.status === 404) {
              setIsError(true);
            }
          });
      }
    });
  }, []);
  if (isError) return <NoDocs />;
  if (data.length === 0 || categories === 0 || folders === 0) return <LoadingComp />;
  return (
    <ScrollView
      contentContainerStyle={{
        marginTop: 100,
        width: "100%",
      }}>
      {data.map((doc) => {
        //console.log(doc);
        return Object.entries(doc).map(([key, value]) => {
          //console.log(key);
          return (
            <DocSection name={value["name"]} size={23}>
              {Object.entries(value).map(([key2, value2]) => {
                //console.log(key2);
                if (key2 != "name") {
                  return (
                    <DocSection name={value2["name"]}>
                      {Object.entries(value2).map(([key3, value3]) => {
                        if (key3 == "without_folder") {
                          return Object.entries(value3).map(([key4, value4]) => {
                            return (
                              <DocListItem
                                idDoc={value4.id}
                                name={value4.display_name ?? value4.name}
                                date={value4.created_at.substring(0, 10)}
                                url={`https://test2.enterpriseesolutions.com` + value4.path}
                                type={value4.extension}
                                size={value4.size}
                              />
                            );
                          });
                        }
                        if (key3 != "name" && key3 != "without_folder") {
                          return (
                            <DocSection name={value3["name"]} size={15}>
                              {Object.entries(value3).map(([key5, value5]) => {
                                console.log("fddsfds", value5);
                                if (key5 != "name") {
                                  return (
                                    <DocListItem
                                      idDoc={value5.id}
                                      name={value5.display_name ?? value5.name}
                                      date={value5.created_at.substring(0, 10)}
                                      url={`https://test2.enterpriseesolutions.com` + value5.path}
                                      type={value5.extension}
                                      size={value5.size}
                                    />
                                  );
                                }
                              })}
                            </DocSection>
                          );
                        }
                      })}
                    </DocSection>
                  );
                }
              })}
            </DocSection>
          );

          /*  let investments=doc[x]
          for (let x2 in investments ) {
            console.log(investments['name']);
            return (
              <DocSection name={investments['name']}>
                 
             {data.map((cat) => {
              let cats = cat[x][x2];
              for (let x3 in cats ) {
                return (
                  <DocSection name={cats["name"]}>
                    {data.map((fdr) => {
              let fdrs = fdr[x][x2][x3];
              for (let x4 in fdrs ) {
                return (
                  <DocSection name={fdrs["name"]}>
              {data.map((docu) => {
              let docus = docu[x][x2][x3];
              return( Object.entries(docus).map(([key, value]) => {
                console.log(key);
               if(key != "name"){
                  let document = docus[key];
              
                  return(<DocListItem
                    name={document.display_name ?? document.name}
                    date={document.created_at.substring(0, 10)}
                    url={`https://test2.enterpriseesolutions.com/` + document.path}
                    type={document.extension}
                    size={document.size}
                  /> );
                }
              
               
              }));
              
            })}

                    </DocSection>
                 
                 );
              }
            })}
                  </DocSection>
                 
              );
              }
          
           
              
              
            })}

                </DocSection>
        );
          } */
        });

        /* return (
          <DocSection name={doc[1].name}>
            {data.map((item) => {
              if (item.category_name === doc) {
                return (
                  <DocListItem
                    name={item.display_name ?? item.name}
                    date={item.created_at.substring(0, 10)}
                    url={`https://test2.enterpriseesolutions.com/` + item.path}
                    type={item.extension}
                    size={item.size}
                  />
                );
              }
            })}  
          </DocSection>
        ); */
      })}

      {/* <Grid
        style={{ flex: 1, width: "100%" }}
        renderItem={_renderItem}
        data={data}
        numColumns={cols}
      /> */}
      <View style={{ height: 100, width: 1 }} />
    </ScrollView>
  );
}

export default Documents;

function NoDocs() {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f7f8fa",
      }}>
      <Image source={img} style={{ width: 200, height: 200 }} />
      <Text style={{ fontSize: 20, fontFamily: "Roboto_700Bold", color: "#909398" }}>
        S7 Equity has not uploaded any documents yet
      </Text>
      <Text style={{ fontSize: 20, fontFamily: "Roboto_700Bold", color: "#909398", marginTop: 5 }}>
        Documents will appear here
      </Text>
    </View>
  );
}
