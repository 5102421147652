import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button, Spinner, Text } from "@ui-kitten/components";
import axios from "axios";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextInput, BackHandler, View } from "react-native";
import { useToast } from "react-native-toast-notifications";
import { useNavigate } from "react-router-native";
import {APIadminsURL} from "@env";
function ProfilePage() {
  const [email, setemail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
  });
  const onSubmit = (data) => {
    setIsLoading(true);
    AsyncStorage.getItem("token").then((res) => {
      axios
        .post(`https://test2.enterpriseesolutions.com/public/api/change-password`, data, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((res) => {
          if (res.data.status === 400) {
            toast.show(res.data.message, {
              type: "danger",
              placement: "bottom",
            });
          } else if (res.data.status === 200) {
            toast.show("password changed successfully", {
              type: "success",
              placement: "bottom",
            });
          }
          console.log(res.data);
          setIsLoading(false);
        });
    });
  };

  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      if (res !== null) {
        axios
          .get(`https://test2.enterpriseesolutions.com/public/api/profile`, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            setemail(res.data.email);
          })
          .catch((res) => {
            if (res.response.status === 401) {
              navigate("/login");
            }
          });
      }
    });
  }, []);

  const LoadingIndicator = (props) => (
    <View style={{ justifyContent: "center", alignItems: "center", marginRight: 10 }}>
      <Spinner status="basic" size="small" />
    </View>
  );

  useEffect(() => {
    const backAction = () => {
      navigate(-1);
      return true;
    };

    const backHandler = BackHandler.addEventListener("hardwareBackPress", backAction);

    return () => backHandler.remove();
  }, []);

  return (
    <>
      <View
        style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "80%",
            backgroundColor: "#fff",
            maxWidth: 500,
            height: 520,
            maxHeight: 520,
            borderRadius: 20,
            boxShadow: "1px 2px 9px #ddd",
            elevation: 10,
            shadowColor: "#555",
            marginTop: 100,
          }}>
          <View style={{ width: "80%", alignSelf: "center", display: "flex" }}>
            <Text
              style={{
                fontFamily: "Roboto_700Bold",
                fontSize: 16,
                marginBottom: 4,
                alignSelf: "flex-start",
                marginTop: 20,
              }}>
              Update your account information:
            </Text>
          </View>
          <View style={{ width: "80%", marginTop: 20 }}>
            <Text style={{ fontFamily: "Roboto_400Regular", marginBottom: 4 }}>Email:</Text>
            <TextInput
              value={email}
              editable={false}
              style={{
                marginBottom: 20,
                backgroundColor: "#f7f9fc",
                borderColor: "#e4e9f2",
                borderWidth: 1,
                height: 40,
                borderRadius: 4,
                padding: 7,
                width: "100%",
              }}
            />
            <Text style={{ fontFamily: "Roboto_400Regular", marginBottom: 4 }}>
              Current Password:
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
                minLength: 6,
              }}
              name="old_password"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  secureTextEntry
                  value={value}
                  onChangeText={onChange}
                  onBlur={onBlur}
                  style={{
                    marginBottom: 30,
                    backgroundColor: "#f7f9fc",
                    borderColor: "#e4e9f2",
                    borderWidth: 1,
                    height: 40,
                    borderRadius: 4,
                    padding: 7,
                    width: "100%",
                  }}
                />
              )}
            />

            <Text style={{ fontFamily: "Roboto_400Regular", marginBottom: 4 }}>
              New Password:
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
                minLength: 6,
              }}
              name="new_password"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  secureTextEntry
                  value={value}
                  onChangeText={onChange}
                  onBlur={onBlur}
                  style={{
                    marginBottom: 30,
                    backgroundColor: "#f7f9fc",
                    borderColor: "#e4e9f2",
                    borderWidth: 1,
                    height: 40,
                    borderRadius: 4,
                    padding: 7,
                    width: "100%",
                  }}
                />
              )}
            />
            <Text style={{ fontFamily: "Roboto_400Regular", marginBottom: 4 }}>
              Confirm New Password:
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
                minLength: 6,
              }}
              name="confirm_password"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  secureTextEntry
                  value={value}
                  onChangeText={onChange}
                  onBlur={onBlur}
                  style={{
                    marginBottom: 30,
                    backgroundColor: "#f7f9fc",
                    borderColor: "#e4e9f2",
                    borderWidth: 1,
                    height: 40,
                    borderRadius: 4,
                    padding: 7,
                    width: "100%",
                  }}
                />
              )}
            />
          </View>
          <Button
            disable={isLoading}
            onPress={handleSubmit(onSubmit)}
            style={{ width: 150, marginTop: 12, marginBottom: 20 }}
            accessoryLeft={isLoading ? <LoadingIndicator /> : null}
            status="success">
            {(evaProps) => (
              <Text style={{ fontFamily: "Roboto_500Medium", color: "white" }}>UPDATE</Text>
            )}
          </Button>
        </View>
      </View>
    </>
  );
}

export default ProfilePage;
