import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useEffect, useState } from "react";
import { ScrollView, View, Text, Dimensions } from "react-native";
import Grid from "react-native-grid-component";
import { useNavigate } from "react-router-native";
import {APIadminsURL} from "@env";
import Card from "../../../components/card";
import LoadingComp from "../../../components/loading";
//TODO : add resoponsive navbar (use Dimensions)
import Cardsub from "../../../components/cardsub";

function All_investments() {
  const [offers, setOffers] = useState([]);

  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isError, setIsError] = useState(false);
  let cols;
  const windowWidth = Dimensions.get("window").width;
  if (windowWidth < 500) {
    cols = 1;
  } else if (windowWidth >= 500 && windowWidth < 1150) {
    cols = 2;
  } else {
    cols = 3;
  }

  useEffect(() => {
    AsyncStorage.getItem("token")
      .then((res) => {
        if (res !== null) {
          axios
            .get(`https://test2.enterpriseesolutions.com/public/api/myoffers`, {
              headers: { Authorization: `Bearer ${res}` },
            })
            .then((result) => {
              console.log("data from myoffers: ", result.data);
              if (result.data.length > 0) {
                
                setOffers(result.data);
              
              }
            })
            .catch((res) => {
                //navigate("/login");
              
            });
         
      
        } else {
          navigate("/login");
        }
      })
      .catch(() => {
        console.log("no token found");
      });
  }, []);
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      if (res !== null) {
        axios
          .get(`https://test2.enterpriseesolutions.com/public/api/myinvestments`, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            setData(res.data);
          })
          .catch((res) => {
            if (res.response.status === 401) {
              navigate("/login");
            }
            if (res.response.status === 404) {
              setIsError(true);
            }
          });
      } else {
        navigate("/login");
      }
    });
  }, []);
  if (isError) return <NoDocs />;
  if (!data) return <LoadingComp />;

  return (
    <ScrollView
      style={{ backgroundColor: "#f3f3f3" }}
      contentContainerStyle={{
        
        marginTop: 100,
      
        width: "100%",
        marginHorizontal: "auto",
      }}>
        <View style={{  
                    }}>
      {offers.length > 0 && (
            <View style={{width: '100%',placeContent: "center",
            flexDirection: 'row', 
            flexWrap: 'wrap' }}>
              {offers ? (
                offers.map((inv, key) => {
                  return (
                    <View style={{  height:"auto", width: 'auto', 
                    margin: 20, 
                    }}>
                    <Cardsub
                      offerings_mode
                      maxwidth={410}
                      height={600}
                      id={inv.id}
                      name={inv.name}
                      status={inv.status}
                      equity_goal={inv.display_raise == 0 ? null : inv.equity_goal}
                      min_invest={inv.min_invest}
                      max_invest={inv.max_invest}
                      closing_date={inv.display_close == 0 ? null : inv.closing_date}
                      photo={inv.cover}
                      city={inv.city}
                      country={inv.country}
                      street_name={inv.street_name}
                      tags={inv.tags}
                      sponsor={inv.sponsors}
                      ROI={inv.ROI}
                      hold_time={inv.hold_time}
                      key={key}
                      viewAll={false}
                    /></View>
                  );
                })
              ) : (
                <View
                  style={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f0f",
                    borderRadius: 10,
                  }}>
                  <Text>No offers available</Text>
                </View>
              )}
            </View>
          )}</View>
    </ScrollView>
  );
}

export default All_investments;

