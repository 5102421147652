import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useEffect, useState } from "react";
import { Text, Dimensions, StyleSheet, TouchableOpacity } from "react-native";
import { View } from "react-native-web";
import { WebView } from "react-native-web-webview";
import { useParams } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-native";
import { useToast } from "react-native-toast-notifications";
import DocListItem from "../../../components/documents/doclistitem";
import LoadingComp from "../../../components/loading";
import Card from "../../../components/card";
import CompactCard from "../../../components/compactcard";
import { Button } from "@ui-kitten/components";
import {APIadminsURL} from "@env";
function Summary(props) {
  const width = Dimensions.get("window").width;
  const { investment } = useParams();
  const [approvement, setApprovement] = useState(null);
  const [investmentbody, setInvestmentbody] = useState("");
  const [dataInvest, setDataInvest] = useState("");

  const ismobile = width < 768;
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      axios
        .get(`https://test2.enterpriseesolutions.com/public/api/investment/` + investment, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((investmentx) => {
          setInvestmentbody(investmentx?.data.investment);
          setApprovement(investmentx?.data.investment.approvement);

          //console.log(investmentx?.data.investment);
        });
    });
  }, []);

  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const toast = useToast();

  const { id } = useParams();
  const [data, setData] = useState({});
  const [visible, setVisible] = useState(false);
  const [sign, setSign] = useState(null);
  const [docs, setDocs] = useState([]);
  const [disabledbtn, setdisabledbtn] = useState(true);
  const [errorSubmit, seterrorSubmit] = useState(true);
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      if (res !== null) {
        axios
          .get(`https://test2.enterpriseesolutions.com/public/api/investment/` + investment, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            //res.data.forEach(myFunction);
            console.log(res.data);
            setDataInvest(res.data.investment);
           
          });
      }
    });
  }, []);
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      axios
        .get(`https://test2.enterpriseesolutions.com/public/api/subscription/` + id, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((result) => {
          setData(result?.data);
          console.log(result?.data.investor_type);
        });
      axios
        .get(
          `https://test2.enterpriseesolutions.com/public/api/subscription_uploaded_documents/` + id,
          {
            headers: { Authorization: `Bearer ${res}` },
          }
        )
        .then((resultdocs) => {
          setDocs(resultdocs?.data);
          console.log(resultdocs?.data);
        });
    });
  }, []);

  if (!data) return <LoadingComp />;
  return (
    <View style={{ backgroundColor: "#f3f3f3",height: "102%" }}>
      <View
        style={{
          flexDirection: ismobile ? "column" : "row",
          width: "95%",
          alignSelf: "center",
          flexWrap: "wrap",
          justifyContent: "center",
          marginTop: 100,
        }}>
        <View style={{ width: ismobile ? "100%" : 450, alignItems: "center" }}>
          {ismobile ? (
            <CompactCard
              id={investmentbody?.id}
              name={investmentbody?.name}
              status={investmentbody?.status}
              equity_goal={investmentbody?.equity_goal}
              min_invest={investmentbody?.min_invest}
              max_invest={investmentbody?.max_invest}
              closing_date={investmentbody?.closing_date}
              city={investmentbody?.city}
              country={investmentbody?.country}
              street_name={investmentbody?.street_name}
              tags={investmentbody?.tags}
            />
          ) : (
            <Card
              height={500}
              id={investmentbody?.id}
              name={investmentbody?.name}
              status={investmentbody.status}
              equity_goal={investmentbody.equity_goal}
              min_invest={investmentbody.min_invest}
              max_invest={investmentbody.max_invest}
              closing_date={investmentbody.closing_date}
              photo={investmentbody.cover}
              city={investmentbody.city}
              country={investmentbody.country}
              street_name={investmentbody.street_name}
              tags={investmentbody.tags}
            />
          )}
        </View>
        <View style={{ flex: 1 }}>
          <View
            style={{
              overflow: "auto",
              backgroundColor: "#ffffff",
              borderRadius: 15,
              padding: 25,
              minWidth: ismobile ? null : 440,
              zIndex: 9,
              flex: 12,
            }}>
            <View
              style={{
                alignItems: "flex-end",
              }}>
              {" "}
              <Button
                onPress={() => {
                  navigate(-1);
                }}
                status="success"
                style={{
                  width: "10%",
                  textAlign: "right",
                }}>
                Back
              </Button>
            </View>
            <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 16, color: "#333", flex: 1 }}>
            BASIC INFORMATION
            </Text>
            <View
              style={{
                flex: 1,
                flexDirection: ismobile ? "column" : "row",
                justifyContent: "space-apart",
                height: ismobile ? 60 : 50,
                paddingTop: 20,
                paddingLeft: 10,
              }}>
              <BasicInfoLine
                rowFix={ismobile ? "auto" : 6}
                name="Investment Amount"
                value={currency(data.commitment)}
              />

              <BasicInfoLine
                rowFix={ismobile ? "auto" : 6}
                name="Investor Type"
                value={
                  data.investor_type == 1
                    ? "Accredited investor"
                    : data.investor_type == 2
                    ? "Friends, family and business associate"
                    : data.investor_type == 3
                    ? "others"
                    : ""
                }
              />
            </View>
            <Text
              style={{
                flex: 1,
                paddingLeft: 10,
                fontFamily: "Roboto_700Bold",
                fontSize: 16,
                color: "#000",
                paddingTop: ismobile ? 40 : 15,
              }}>
              Account Information:
            </Text>
            <View
              style={{
                justifyContent: "space-apart",
                paddingTop: ismobile ? 5 : 10,
                paddingLeft: 10,
                marginLeft: 20,
              }}>
              {(() => {
                if (data.legal_type == 1) {
                  return [
                    <View
                      style={{
                        flexDirection: ismobile ? "column" : "row",
                      }}>
                      <BasicInfoLine
                        rowFix={ismobile ? "auto" : 0.5}
                        name="Type of Legal Entity"
                        value={
                          data.legal_type == 1
                            ? "Individual"
                            : data.legal_type == 2
                            ? "Organization"
                            : "NA"
                        }
                      />
                      <BasicInfoLine
                        rowFix={ismobile ? 1 : 0.5}
                        name="Subscriber  Legal Name"
                        value={
                          (data.legal_first_name ? data.legal_first_name : "") +
                          " " +
                          (data.legal_middle_name ? data.legal_middle_name : "") +
                          " " +
                          (data.legal_last_name ? data.legal_last_name : "")
                        }
                      />
                    </View>,

                    <View
                      style={{
                        flexDirection: ismobile ? "column" : "row",
                      }}>
                      <BasicInfoLine
                        rowFix={ismobile ? 1 : 0.5}
                        name="Email Address"
                        value={data.email}
                      />
                      <BasicInfoLine
                        rowFix={ismobile ? 1 : 0.5}
                        name="Phone Number"
                        value={data.phone ? data.phone : "NA"}
                      />
                    </View>,
                    <View
                      style={{
                        flexDirection: ismobile ? "column" : "row",
                      }}>
                      <BasicInfoLine
                        rowFix={ismobile ? 1 : 0.5}
                        name="Country of citizenship"
                        value={data.citizenship_countryName ? data.citizenship_countryName : "NA"}
                      />
                      <BasicInfoLine
                        rowFix={ismobile ? 1 : 0.5}
                        name="Address"
                        value={data.street_line1 || data.city || data.state ? (
                          (data.street_line1 ? data.street_line1 + ", " : "") +
                            
                            (data.city ? data.city + ", " : "") +
                            
                            (data.state ? data.state : "") ?? "") : "NA"
                        }
                      />
                    </View>,
                  ];
                } else {
                  return [
                    <View
                      style={{
                        flexDirection: ismobile ? "column" : "row",
                      }}>
                      <BasicInfoLine
                        name="Type of Legal Entity"
                        value={
                          data.legal_type == 1
                            ? "Individual"
                            : data.legal_type == 2
                            ? "Organization"
                            : "NA"
                        }
                      />
                      <BasicInfoLine
                        name="Organization Name"
                        value={data.organization_name ? data.organization_name : "NA"}
                      />
                    </View>,
                    <View
                      style={{
                        flexDirection: ismobile ? "column" : "row",
                      }}>
                      <BasicInfoLine name="Corporation Number" value={data.tax_id ? data.tax_id : "NA"} />
                    </View>,
                  ];
                }
              })()}
            </View>

            {(() => {
              if (data.legal_type == 2) {
                return [
                  <Text
                    style={{
                      fontFamily: "Roboto_700Bold",
                      fontSize: 16,
                      color: "#000",
                      paddingLeft: 10,
                      paddingTop: 15,
                    }}>
                    Signatory:
                  </Text>,
                  <View
                    style={{
                      overflow: "auto",
                      justifyContent: "space-apart",
                      paddingTop: 10,
                      paddingLeft: 10,
                      marginLeft: 20,
                    }}>
                    <View
                      style={{
                        flexDirection: ismobile ? "column" : "row",
                      }}>
                      <BasicInfoLine
                        name="Signatory Type"
                        value={
                          data.signatory_type == 1
                            ? "Individual"
                            : data.signatory_type == 2
                            ? "legal entity"
                            : "NA"
                        }
                      />
                    </View>
                    {(() => {
                      if (data.signatory_type == 2) {
                        return [
                          <View
                            style={{
                              flexDirection: ismobile ? "column" : "row",
                            }}>
                            <BasicInfoLine
                              name="Entity name"
                              value={data.signatory_entity_name ? data.signatory_entity_name : "NA"}
                            />
                            <BasicInfoLine
                              name="Entity title"
                              value={data.signatory_entity_title ? data.signatory_entity_title : "NA"}
                            />
                          </View>,
                        ];
                      }
                    })()}
                    <View
                      style={{
                        flexDirection: ismobile ? "column" : "row",
                      }}>
                      <BasicInfoLine
                        name={data.signatory_type == 2 ? "contact person name" : "name"}
                        value={
                          (data.signatory_first_name ? data.signatory_first_name : "") +
                          " " +
                          (data.signatory_middle_name ? data.signatory_middle_name : "") +
                          " " +
                          (data.signatory_last_name ? data.signatory_last_name : "")
                        }
                      />
                      <BasicInfoLine
                        name={data.signatory_type == 2 ? "contact person email" : "Email"}
                        value={data.signatory_email ? data.signatory_email : "NA"}
                      />
                    </View>
                    <View
                      style={{
                        flexDirection: ismobile ? "column" : "row",
                      }}>
                      <BasicInfoLine
                        name={data.signatory_type == 2 ? "contact person phone" : "Phone"}
                        value={data.signatory_phone ? data.signatory_phone : "NA"}
                      />
                    </View>
                  </View>,
                ];
              }
            })()}
          </View>
          {docs.length != 0 ? (
            <View
              style={{
                backgroundColor: "#fff",
                borderRadius: 15,
                padding: 25,
                minWidth: ismobile ? null : 440,
                zIndex: 9,
                marginTop: 20,
              }}>
              <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 16, color: "#000" }}>
                Uploaded Documents
              </Text>
              <View
                style={{
                  flexDirection: "column",
                  flexWrap: "wrap",
                  justifyContent: "space-apart",
                  height: ismobile ? null : 150,
                }}>
                {docs?.map((doc) => {
                  return (
                    <DocListItem
                      key={doc.id}
                      width="40%"
                      name={doc.name}
                      url={`https://test2.enterpriseesolutions.com` + doc.path}
                      type={doc.extension}
                      date={doc.created_at.substring(0, 10)}
                      size={doc.size}
                    />
                  );
                })}
              </View>
            </View>
          ) : null}
          {dataInvest.ownership == "shares" && <View
            style={{
              backgroundColor: "#fff",
              borderRadius: 15,
              padding: 25,
              minWidth: ismobile ? null : 440,
              zIndex: 9,
              marginTop: 20,
            }}>
            <Text
              style={{
                fontFamily: "Roboto_700Bold",
                fontSize: 16,
                color: "#000",
                marginBottom: 10,
              }}>
              Draft Share Certificate
            </Text>
            <WebView
              style={{ width: "100%", height: 500 }}
              source={{
                uri: ismobile
                  ? "https://docs.google.com/viewer?embedded=true&url=" +
                    `https://test2.enterpriseesolutions.com/print/draft/` +
                    id
                  : `https://test2.enterpriseesolutions.com/print/draft/` + id,
              }}
            />
          </View>}

          {(() => {
            if (approvement == "Approved") {
              return (
                <View
                  style={{
                    width: "100%",
                    alignSelf: "center",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    borderRadius: 10,
                    marginVertical: 20,
                  }}>
                  {/*  <Button
          style={{ marginRight: 10, backgroundColor: "#939393", borderColor: "#939393" }}
          onPress={() => {
            prev();
          }}
          status="success"
         >
          Previous
        </Button> */}
                  <Button disabled status="success">
                    Pay Now
                  </Button>
                </View>
              );
            }
          })()}
        </View>
      </View>
    </View>
  );
}

export default Summary;

function BasicInfoLine({ name, value, rowFix = 0.5 }) {
  return (
    <Text
      style={{
        flex: rowFix,
        fontFamily: "Roboto_700Bold",
        fontSize: 14,
        color: "#969696",
        marginTop: 5,
      }}>
      {name}:{" "}
      <Text
        style={{
          fontFamily: "Roboto_400Regular",
          fontSize: 14,
          color: "#969696",
        }}>
        {value}
      </Text>{" "}
    </Text>
  );
}
function currency(num) {
  if (num)
    return num.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    });
}
const styles = StyleSheet.create({
  container: {
    minHeight: 192,
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  inputerror: {
    fontFamily: "Roboto_400Regular",
    fontSize: 12,
    color: "#f00",
  },
});
