import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { Button, Card, Modal } from "@ui-kitten/components";
import { useState, useEffect } from "react";
import { StyleSheet, Text, TouchableOpacity, View, Linking, Image, Dimensions } from "react-native";
import { Icon } from "react-native-eva-icons";
import { WebView } from "react-native-web-webview";
import { MaterialIcons } from "@expo/vector-icons";
import {APIadminsURL} from "@env";

function DocListItem({ name, url, type, date, size, compact, width = "100%", idDoc = 0 }) {
  if (size) {
    size = formatBytes(size);
  }
  Image.getSize(url, (width, height) => {
    setPicDim({ width, height });
  });
  function trackdocFun(params) {
    if (idDoc) {
      AsyncStorage.getItem("token").then((res) => {
        console.log(res);
        let data = { doc_id: idDoc };
        console.log("mydata", data);
        if (res !== null) {
          axios
            .post(`https://test2.enterpriseesolutions.com/public/api/trackdoc`, data, {
              headers: { Authorization: `Bearer ${res}` },
            })
            .then((res) => {
              console.log(res);
            })
            .catch((res) => {
              //navigate("/login");
            });
        }
      });

      //alert(idDoc);
    }
  }

  const [visible, setVisible] = useState(false);
  const [imgvisible, setImgVisible] = useState(false);
  const [picDim, setPicDim] = useState({ width: 0, height: 0 });
  const screenwidth = Dimensions.get("window").width;
  const screenheight = Dimensions.get("window").height;

  const ismobile = screenwidth < 768;
  //"https://view.officeapps.live.com/op/embed.aspx?wdPrint=0&src=" + url
  //https://www.clickdimensions.com/links/TestPDFfile.pdf
  //https://calibre-ebook.com/downloads/demos/demo.docx
  const handleVisible = (state) => {
    if (type === "pdf") {
      setVisible(state);
    } else if (type === "docx") {
      Linking.openURL("https://drive.google.com/viewer?url=" + url).catch((err) => {
        console.log(err);
      });
    } else {
      setImgVisible(state);
    }
  };
  const downloadfile = () => {
    if (url) {
      // Linking.openURL(`https://test2.enterpriseesolutions.com/public` + url).catch((err) => {
      //   console.log(err);
      // });
      Linking.openURL(url).catch((err) => {
        console.log(err);
      });
    } else {
      console.log("document has no path url");
    }
  };
  return (
    <>
      <TouchableOpacity
        onPress={() => {
          handleVisible(true);
          trackdocFun();
        }}
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginVertical: 5,
          paddingHorizontal: 15,
          //width,
          justifyContent: "space-between",
        }}>
        <View style={{ flexDirection: "row", alignItems: "center", flexShrink: 1 }}>
          <Icon
            name={type === "pdf" ? "file-text-outline" : "image-outline"}
            width={25}
            height={25}
            fill="#8DC641"
          />

          <Text
            style={{
              fontFamily: "Roboto_400Regular",
              marginLeft: 10,
              flexShrink: 1,
              fontSize: 16,
            }}>
            {name}
          </Text>
        </View>
        {!compact && (
          <Text
            style={{
              fontFamily: "Roboto_300Light",
              textAlign: "right",
              color: "gray",
              fontSize: 12,
            }}>
            {!compact && size == 0 ? "" : size} {date}
          </Text>
        )}
      </TouchableOpacity>
      <Modal
        style={{ width: ismobile ? "90%" : 700, maxWidth: ismobile ? "100%" : 800, height: "auto" }}
        visible={visible}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => handleVisible(false)}>
        <Card
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: ismobile ? "none" : "fixed",
            left: "50%",
            transform: "translate(-50%,0)",
            top: "2%",
          }}>
          <WebView
            style={{ width: ismobile ? "100%" : 700, height: screenheight - 120 }}
            source={{
              uri: ismobile ? "https://docs.google.com/viewer?embedded=true&url=" + url : url,
            }}
          />
          <View style={{ margin: 5, flexDirection: "row", justifyContent: "space-around" }}>
            <MaterialIcons
              style={{ backgroundColor: "rgb(141, 198, 65)", borderRadius: 5 }}
              onPress={() => {
                downloadfile();
              }}
              name="fullscreen"
              size={48}
              color="white"
            />
            <Button
              status="success"
              style={{ backgroundColor: "#969696", borderColor: "#969696" }}
              onPress={() => {
                handleVisible(false);
              }}>
              OK
            </Button>
          </View>
        </Card>
      </Modal>
      {/**modal to display image */}
      <Modal
        style={{ width: ismobile ? "100%" : 700, maxWidth: 900, height: screenheight }}
        visible={imgvisible}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => handleVisible(false)}>
        <Card
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "fixed",
            left: "50%",
            transform: "translate(-50%,-50%)",
            top: "50%",
          }}>
          <Image
            style={{
              width: picDim.width, //ismobile ? "100%" : screenwidth * 0.6,
              height: picDim.height, //screenheight - 120,
              maxWidth: ismobile ? screenwidth - 20 : screenwidth * 0.6,
              maxHeight: screenheight - 120,
              resizeMode: "center",
            }}
            source={{ uri: url }}
          />
          <View
            style={{
              marginTop: 20,
              margin: 5,
              flexDirection: "row",
              justifyContent: "space-around",
            }}>
            <MaterialIcons
              style={{ backgroundColor: "rgb(141, 198, 65)", borderRadius: 5 }}
              onPress={() => {
                downloadfile();
              }}
              name="fullscreen"
              size={48}
              color="white"
            />
            <Button
              status="success"
              style={{ backgroundColor: "#969696", borderColor: "#969696" }}
              onPress={() => {
                handleVisible(false);
              }}>
              OK
            </Button>
          </View>
        </Card>
      </Modal>
    </>
  );
}
const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
});
export default DocListItem;
function formatBytes(a, b = 2) {
  if (!+a) return "0 Bytes";
  const c = b < 0 ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  }`;
}
