import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button } from "@ui-kitten/components";
import { useState, useEffect } from "react";
import { Dimensions, Text, View } from "react-native";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "react-native-toast-notifications";
import axios from "axios";
//import Card from "../../../components/card";
import Cardsub from "../../../components/cardsub";
import RenderHTML from "react-native-render-html";
import CardSubscription from "../../../components/cardsubscription";
import LoadingComp from "../../../components/loading";
import {APIadminsURL} from "@env";
function WelcomeSub() {
  const [welcomemsg, setWelcomemsg] = useState("Welcome to the subscription page");
  const [nameInvestment, setNameInvestment] = useState('"name of investment"');
  const toast = useToast();
  const navigate = useNavigate();
  const width = Dimensions.get("window").width;
  const ismobile = width < 900;
  const { id } = useParams();
  const [investmentbody, setInvestmentbody] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      axios
        .get(`https://test2.enterpriseesolutions.com/public/api/investment/` + id, {
          headers: { Authorization: `Bearer ${res}` },
        })
        .then((investmentx) => {
          setInvestmentbody(investmentx?.data.investment);
          //console.log(investmentx?.data.investment);
        }).catch((res) => {
          if (res.response.status === 401) {
            navigate("/login");
          }
        });
    });
  }, []);
  function DeclineSubscription() {
    //alert("pl");
    let data = { investment: id };
    AsyncStorage.getItem("token").then((res) => {
      if (res !== null) {
        axios
          .post(`https://test2.enterpriseesolutions.com/public/api/subscription/decline/` + id, data, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            //res.data.forEach(myFunction);
            //console.log(res.data.message);
            if (res.data.status == 200) {
              toast.show(res.data.message, {
                type: "danger",
                placement: "bottom",
              });
              navigate("/dashboard");
            }
          })
          .catch((res) => {
            //navigate("/login");
            console.log(res);
          });
      }
    });
  }
  function AddSubscription() {
    let data = { investment: id, step: 0 };
    console.log(data);
    AsyncStorage.getItem("token").then((res) => {
      if (res !== null) {
        axios
          .post(`https://test2.enterpriseesolutions.com/public/api/subscription/create`, data, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            //res.data.forEach(myFunction);
            console.log(res);

            if (res.data.status == 200) {
              navigate("/subscription/" + id + "/" + res.data.subscription_id);
            }
          })
          .catch((res) => {
            //navigate("/login");
            console.log(res);
          });
      }
    });
  }
  useEffect(() => {
    AsyncStorage.getItem("token").then((res) => {
      if (res !== null) {
        axios
          .get(`https://test2.enterpriseesolutions.com/public/api/investment/` + id, {
            headers: { Authorization: `Bearer ${res}` },
          })
          .then((res) => {
            //res.data.forEach(myFunction);
            console.log(res.data.investment);
            setNameInvestment(res.data.investment.name);
            setWelcomemsg(res.data.investment.welcome_message);
            setLoading(false);
          });
      }
    });
  }, []);
  if (loading) return <LoadingComp />;
  return (
    <View style={{ backgroundColor: "#f3f3f3", height: "100%" }}>
      <View
        style={{
          width: "95%",
          alignSelf: "center",
          marginTop: 100,
          backgroundColor: "#f3f3f3",
          flexDirection: ismobile ? "column" : "row",
        }}>
        <View style={{ width: ismobile ? "100%" : 450, alignItems: "center" }}>
          {ismobile ? (
            <CardSubscription
              id={investmentbody?.id}
              name={investmentbody?.name}
              status={investmentbody?.status}
              equity_goal={investmentbody?.equity_goal}
              min_invest={investmentbody?.min_invest}
              max_invest={investmentbody?.max_invest}
              closing_date={investmentbody?.closing_date}
              city={investmentbody?.city}
              country={investmentbody?.country}
              street_name={investmentbody?.street_name}
              tags={investmentbody?.tags}
              sponsor={investmentbody.sponsors}
              ROI={investmentbody.ROI}
              hold_time={investmentbody.hold_time}
            />
          ) : (
            <Cardsub
              height={500}
              id={investmentbody?.id}
              name={investmentbody?.name}
              status={investmentbody.status}
              equity_goal={investmentbody.equity_goal}
              min_invest={investmentbody.min_invest}
              max_invest={investmentbody.max_invest}
              closing_date={investmentbody.closing_date}
              photo={investmentbody.cover}
              city={investmentbody.city}
              country={investmentbody.country}
              street_name={investmentbody.street_name}
              tags={investmentbody.tags}
              sponsor={investmentbody.sponsors}
              ROI={investmentbody.ROI}
              hold_time={investmentbody.hold_time}
            />
          )}
        </View>
        <View style={{ flex: 1 }}>
          <View
            style={{
              alignItems: "center",
              width: "100%",
              minHeight: 630,
              backgroundColor: "#fff",
              borderRadius: 10,
              alignSelf: "center",
              padding: 20,
              marginBottom: 20,
            }}>
            <View
              style={{
                width: "80%",
                height: 200,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-evenly",
                //backgroundColor: "#8dc641",
                borderRadius: 10,
              }}>
              <Text
                style={{
                  fontFamily: "Roboto_700Bold",
                  fontSize: ismobile ? 20 : 32,
                  color: "#939393",
                }}>
                Create a subscription
              </Text>
              {/* <Text style={{ fontFamily: "Roboto_400Regular", fontSize: 16, color: "#000" }}>
                for {nameInvestment}
              </Text> */}
              {/*  <Text style={{ fontFamily: "Roboto_400Regular", fontSize: 16, color: "#000" }}>
                prepared by "person"
              </Text> */}
            </View>
            <View
              style={{
                width: "80%",
                flexDirection: "column",
                justifyContent: "space-evenly",
                marginTop: 25,
              }}>
              <Text
                style={{
                  fontFamily: "Roboto_500Medium",
                  fontSize: 20,
                  color: "#000",
                  marginBottom: 20,
                }}>
                Welcome!
              </Text>
              {(() => {
                if (welcomemsg) {
                  return (
              <RenderHTML source={{ html: `${welcomemsg}` }} />
              );
            }
          })()}
              <View style={{ flexDirection: "row", marginTop: 20 }}>
                <Button
                  onPress={() => AddSubscription()}
                  style={{ borderRadius: 10, marginRight: 10 }}
                  status="success">
                  Create my subscription
                </Button>
                <Button
                  onPress={() => {
                    navigate("/dashboard");
                  }}
                  /*  onPress={() => DeclineSubscription()} */

                  style={{ borderRadius: 10, marginRight: 10 }}
                  status="info">
                  Cancel
                </Button>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

export default WelcomeSub;
