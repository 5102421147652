import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useState } from "react";
import {
  ScrollView,
  View,
  Text,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  TextInput,} from "react-native";
import Grid from "react-native-grid-component";
import { Button, Radio, Modal, RadioGroup, Card as KittenCard } from "@ui-kitten/components";
import { Icon } from "react-native-eva-icons";
import { useForm, Controller } from "react-hook-form";
import Card from "../../components/card";
import LoadingComp from "../../components/loading";
import Select, { components } from "react-select";
import Swal from "sweetalert2";
import {APIadminsURL} from "@env";
import RenderHTML from "react-native-render-html";
import { useNavigate } from "react-router-native";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
//TODO : add resoponsive navbar (use Dimensions)

function InvestmentsPage() {
  

  const navigate = useNavigate();
useEffect(() => {
  AsyncStorage.getItem("token").then((res) => {
    if (res !== null) {
      navigate("/dashboard");
    }
  })
});
useEffect(() => {
  axios.get(`https://test2.enterpriseesolutions.com/public/api/countries`).then((res) => {
    //res.data.forEach(myFunction);
    //console.log(res.data);
    setOptions(res.data);
  });
}, []);
useEffect(() => {
  //alert(`https://test2.enterpriseesolutions.com/public/api/myoffers`);
  axios
    .get(`https://test2.enterpriseesolutions.com/public/api/myoffers`)
    .then((res) => {
      console.log("hethi",res);
      if(res.data.message != 'No available offers'){
        //alert("d5al");
        if (res.data.length > 0) {
          console.log("mydata",res.data);
        setData(res.data);
        setIsData(true);
        
        }
        
      }
      setIsError(false);
    })
    .catch((res) => {
      //alert("med5al");
      //console.log(res);
      setIsError(true);
    });
}, []);
  const [subtype, setSubtype] = useState(0);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      width: "100%",
      height: 40,
      borderColor: "#bdbdbd",
      borderRadius: 5,
      borderWidth: 1,
      marginTop: 10,
      paddingHorizontal: 10,
      backgroundColor: "#00000000",
      fontFamily: "system-ui",
    }),
  };
  function PhoneNumberFun(val) {
    //alert(isValidPhoneNumber(val));
    if (isValidPhoneNumber(val)) {
      //let msg =
      return true;

      //setMinMax
    }
    return false;
  }
  const [visible, setVisible] = useState(false);
  const [visibleM, setVisibleM] = useState(false);
  const width = Dimensions.get("window").width;
  const ismobile = width < 768;
  const [options, setOptions] = useState([]);
  const [invtype, setinvtype] = useState(0);
  const [isData, setIsData] = useState(false);
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(true);
  const [valuePhone, setValuePhone] = useState();
  const [defaultValueCountry, setDefaultValueCountry] = useState({});
  const [investmentId, setInvestmentId] = useState(null);
  const [defaultValueCountryOfCitizenship, setDefaultValueCountryOfCitizenship] = useState({});
  const [InvestorTypeDesc, setInvestorTypeDesc] = useState("");
  const [InvestorTypeTitle, setInvestorTypeTitle] = useState("");
  const CustomOption = ({ children, ...props }) => {
    return (
      <>
        <components.Option {...props}>
          <View
            style={{
              width: "100%",
              alignSelf: "center",
              flexDirection: "row",
              borderRadius: 10,
              marginVertical: 5,
            }}>
            <Text style={{ fontFamily: "Roboto_700Bold" }}> {children}</Text>
          </View>
        </components.Option>
      </>
    );
  };
  function InvestorTypeFun(e) {
    if (e == 1) {
      setInvestorTypeTitle("Accredited investor ");
      setInvestorTypeDesc(`<p style="text-align: justify;margin-bottom: 0px;"><strong>Accredited investors&nbsp;</strong>is defined in section 1.1 of <span style="color: rgb(97, 189, 109);"><a style="text-decoration: none;color:#8dc641;text-decoration-color:#8dc641;" href="https://ca.practicallaw.thomsonreuters.com/0-574-9502?originationContext=document&transitionType=DocumentItem&contextData=(sc.Default)&ppcid=e8c7cb52a8c142aaa9a4a2c23afecc34&firstPage=true" rel="noopener noreferrer" target="_blank">National Instrument 45-106 Prospectus Exemptions</a></span>. Among others, accredited investors could be:</p>
      <ol style="list-style-type: lower-roman;">
          <li style="text-align: justify">An individual whose net income before taxes exceeded $200,000 in each of the two most recent calendar years or whose net income before taxes combined with that of a spouse exceeded $300,000 in each of the two most recent calendar years and who, in either case, reasonably expects to exceed that net income level in the current calendar year.&nbsp;</li>
          <li style="text-align: justify">An individual who beneficially owns financial assets having an aggregate realizable value that, before taxes but net of any related liabilities, exceeds $5,000,000.</li>
      </ol>`);
    } else if (e == 2) {
      setInvestorTypeTitle("Friends , family and business associates");
      setInvestorTypeDesc(`<p style="text-align: justify;margin-bottom: 0px;">Family, Friend and Business Associate Investors are defined in sections 2.6 and 2.6.1 of <a  style="text-decoration: none;color:#8dc641;text-decoration-color:#8dc641;" href="https://ca.practicallaw.thomsonreuters.com/0-574-9502?originationContext=document&transitionType=DocumentItem&contextData=(sc.Default)&ppcid=e8c7cb52a8c142aaa9a4a2c23afecc34" rel="noopener noreferrer" target="_blank"><span  style="color:#8dc641">National Instrument 45-106 Prospectus Exemptions</span></a>. Investors within this category could be:&nbsp;</p>
      <ol style="list-style-type: lower-roman;">
          <li style="color: rgb(0, 0, 0);text-align: justify">A <a style="text-decoration: none;" href="https://ca.practicallaw.thomsonreuters.com/0-562-3673?originationContext=document&transitionType=DocumentItem&contextData=(sc.Default)&ppcid=8607165a845b4334919f697901417bd9" rel="noopener noreferrer" target="_blank"><strong><span style="color: rgb(0, 0, 0);">director</span></strong></a>, <a style="text-decoration: none;" href="https://ca.practicallaw.thomsonreuters.com/6-571-2510?originationContext=document&transitionType=DocumentItem&contextData=(sc.Default)&ppcid=8607165a845b4334919f697901417bd9" rel="noopener noreferrer" target="_blank"><strong><span style="color: rgb(0, 0, 0);">executive officer</span></strong></a> or <a style="text-decoration: none;" href="https://ca.practicallaw.thomsonreuters.com/1-570-3867?originationContext=document&transitionType=DocumentItem&contextData=(sc.Default)&ppcid=8607165a845b4334919f697901417bd9" rel="noopener noreferrer" target="_blank"><strong><span style="color: rgb(0, 0, 0);">control person</span></strong></a> of the issuer or of an affiliate of the issuer. </li>
          <li style="text-align: justify">A spouse, parent, grandparent, brother, sister, child or grandchild of a director, executive officer or control person of the issuer, or of an affiliate of the issuer.</li>
          <li style="text-align: justify">&nbsp;A parent, grandparent, brother, sister, child or grandchild of the spouse of a director, executive officer or control person of the issuer or of an <a style="text-decoration: none;" href="https://ca.practicallaw.thomsonreuters.com/4-561-8146?originationContext=document&transitionType=DocumentItem&contextData=(sc.Default)&ppcid=8607165a845b4334919f697901417bd9" rel="noopener noreferrer" target="_blank"><strong><span style="color: rgb(0, 0, 0);">affiliate </span></strong></a>of the issuer.&nbsp;</li>
          <li style="text-align: justify">A close personal friend of a director, executive officer or control person of the issuer, or of an affiliate of the issuer.</li>
          <li style="text-align: justify">&nbsp;A close business associate of a director, executive officer or control person of the issuer, or of an affiliate of the issuer.</li>
          <li style="text-align: justify">&nbsp;A founder of the issuer or a spouse, parent, grandparent, brother, sister, child, grandchild, close personal friend or close business associate of a founder of the issuer.</li>
          <li style="text-align: justify">&nbsp;A parent, grandparent, brother, sister, child or grandchild of a spouse of a <a style="text-decoration: none;" href="https://ca.practicallaw.thomsonreuters.com/6-574-9405?originationContext=document&transitionType=DocumentItem&contextData=(sc.Default)&ppcid=8607165a845b4334919f697901417bd9" rel="noopener noreferrer" target="_blank"><strong><span style="color: rgb(0, 0, 0);">founder</span></strong></a>&nbsp;of the issuer.</li>
          <li style="text-align: justify">&nbsp;A person of which a majority of the voting securities are beneficially owned by, or a majority of the directors are, persons described in the first seven bullets.</li>
          <li style="text-align: justify">&nbsp;A <a style="text-decoration: none;" href="https://ca.practicallaw.thomsonreuters.com/2-575-5375?originationContext=document&transitionType=DocumentItem&contextData=(sc.Default)&ppcid=8607165a845b4334919f697901417bd9" rel="noopener noreferrer" target="_blank"><strong><span style="color: rgb(0, 0, 0);">trust</span></strong></a>&nbsp;or estate of which all of the beneficiaries or a majority of the trustees or executors are persons described in the first seven bullets.</li>
      </ol>`);
    } else if (e == 3) {
      setInvestorTypeTitle("Others");
      setInvestorTypeDesc("");
    }
    setVisibleM(true);
    //window.scrollTo(0, 0);
    //setInvestorTypeDesc("fdsfdfs")
  }
  function addContact(id) {
    //alert(id);
    setInvestmentId(id);
    setVisible(true);
  }
  const onSubmit = (data) => {
    //alert();
    data['investment_id'] = investmentId ;
    console.log(data);
    let type = 1;
    if (data.legal_type == 1) {
      type = 2;
    }
    data.legal_type = type;
    let investorType = 1;
    if (data.investor_type == 1) {
      investorType = 2;
    } else if (data.investor_type == 2) {
      investorType = 3;
    }
    data.investor_type = investorType;
    axios.post(`https://test2.enterpriseesolutions.com/public/api/SendRequest`, data).then((res) => {
      if (res.data.result == true) {
        Swal.fire({
          title: "Your request was successfully submitted.",
          confirmButtonColor: "#8dc641",
          text: `Thanks for requesting the investor package for this deal. We will create access to a private data room based
          on your information. You should get an email from us within 48 hours. If not, please email us at
          contact@s7equity.ca and an investor relation specialist will connect with you.`,
          icon: "success",
          customClass: {
            container: "swal-text",
            popup: "swal-text",
            title: "swal-title",
            content: "swal-text",
          },
        }).then((result) => {
          setVisible(false);
      });
      }else{
        Swal.fire({
          title: "error!",
          confirmButtonColor: "#8dc641",
          text: `The email already exists in the system.`,
          icon: "error",
          customClass: {
            container: "swal-text",
            popup: "swal-text",
            title: "swal-title",
            content: "swal-text",
          },
        })
      }
      console.log(res.data);
    });
  };
  let cols;
  const windowWidth = Dimensions.get("window").width;
  if (windowWidth < 500) {
    cols = 1;
  } else if (windowWidth >= 500 && windowWidth < 1150) {
    cols = 2;
  } else {
    cols = 3;
  }
  const _renderItem = (data, i) => (
    <View style={{ flex: 1, margin: 1, marginBottom: 20 }}>
      <Card
        id={data.id}
        name={data.name}
        status={data.status}
        equity_goal={data.display_raise == 0 ? null : data.equity_goal}
        min_invest={data.min_invest}
        max_invest={data.max_invest}
        closing_date={data.display_close == 0 ? null : data.closing_date}
        photo={data.cover}
        key={i}
        city={data.city}
        country={data.country}
        street_name={data.street_name}
        tags={data.tags}
        types={data.types}
        onClick={addContact}
        login={0}
      />
    </View>
  );
 
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      legal_first_name: "",
      legal_type: 0,
      legal_middle_name: "",
      city: "",
      company_name: "",
      citizenship_country: "",
      email: "",
      emailOrganization: "",
      job_title: "",
      legal_last_name: "",
      phone: "",
      zipcode: "",
      state: "",
      street_line1: "",
      street_line2: "",
      country: "",
      signatory_type: 0,
      organization_name: "",
      investor_type:0
    },
  });
  if (isError) return <LoadingComp />;

  return ([
    <View   style={{ 
      backgroundColor: "#f3f3f3",
     
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginHorizontal: "auto",
    }} >
        <Text
              style={{
                fontFamily: "Roboto_700Bold",
                marginVertical: 5,
                fontSize: 38,
                color: "#8dc641",
                marginTop: 150,
              }}>
              {isData ? "Featured Deals" : "THERE'S NO ACTIVE DEALS" }
            </Text>
            
            </View>,<View   style={{ 
      backgroundColor: "#f3f3f3",
     
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginHorizontal: "auto",
    }} >
        <Text
              style={{
                fontFamily: "Roboto_400Regular",
                marginVertical: 5,
                fontSize: 20,
                color: "#a2a2a2",
                marginTop: 10,
              }}>{isData ? "Hand picked deals for qualified investors." : "" }
             
            </Text></View>,

    <ScrollView
      style={{ backgroundColor: "#f3f3f3" }}
      contentContainerStyle={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 50,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
        marginHorizontal: "auto",
      }}>
        {(() => {
          if (isData) {
            return [
      <Grid style={{ flex: 1 }} renderItem={_renderItem} data={data} numColumns={cols} />
    ];
  }
})()}
      <View style={{ height: 100, width: 1 }} />
      <Modal
        style={{ width: ismobile ? "80%" : 300, maxWidth: 500 }}
        visible={visible}
        backdropStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        onBackdropPress={() => setVisible(false)}>
        <KittenCard
          style={{
            maxWidth: 900,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "fixed",
            left: ismobile ? "0%" : "50%",
            transform: ismobile ? "translate(-0%,-50%)" : "translate(-50%,-50%)",
            top: "50%",
          }}>
          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            <Text
              style={{
                fontFamily: "Roboto_700Bold",
                marginVertical: 5,
                flex: 0.8,
                fontSize: 22,
                color: "#8dc641",
              }}>
              Request Access
            </Text>
            <View
              style={{
                flex: 0.2,
              }}>
              <TouchableOpacity
                onPress={() => setVisible(false)}
                size={"small"}
                appearance="ghost"
                style={{ width: 10, alignSelf: "flex-end" }}>
                {" "}
                <Icon name="close-outline" width={20} height={20} style={{ top: 0 }} fill="#aaa" />
              </TouchableOpacity>
            </View>
          </View>

          <View
            style={{
              borderRadius: 15,
              paddingRight: 25,
              zIndex: 9,
              marginTop: 0,
              overflowY: "auto",
              overflowX: "hidden",
              maxHeight: "500px",
            }}>
            <Text
              style={{
                fontFamily: "Roboto_700Bold",
                fontSize: 16,
                color: "#333",
                marginTop: 30,
                paddingBottom: 10,
              }}>
              What type of legal entity is the subscriber
            </Text>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name="legal_type"
              render={({ field: { onChange, onBlur, value } }) => (
                <RadioGroup
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: 16,
                    flexDirection: "row",
                    paddingLeft: 10,
                  }}
                  selectedIndex={value}
                  /* value={reqc} */
                  onChange={(e) => {
                    onChange(e); // method from hook form register
                    setSubtype(e); // your method
                  }}
                  onBlur={onBlur}>
                  <Radio status="success">Individual</Radio>
                  <Radio status="success" style={{ paddingLeft: 20 }}>
                    Organization
                  </Radio>
                </RadioGroup>
              )}
            />

            {subtype === 0 && (
              <>
                <View style={{ flexDirection: "row", marginTop: 20 }}>
                  <View
                    style={{
                      flexDirection: "column",
                      marginRight: 10,
                      width: "40%",
                      paddingLeft: 10,
                    }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      First Name <Text style={{ color: "#df0101" }}>*</Text>
                    </Text>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      name="legal_first_name"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          value={value}
                          onChangeText={onChange}
                          onBlur={onBlur}
                          style={{
                            width: "100%",
                            height: 40,
                            borderColor: "#bdbdbd",
                            borderRadius: 5,
                            borderWidth: 1,
                            marginTop: 10,
                            paddingHorizontal: 10,
                          }}
                        />
                      )}
                    />
                    {errors.legal_first_name?.type === "required" ? (
                      <Text style={styles.inputerror}>This first name is required</Text>
                    ) : errors.legal_last_name?.type === "required" ? (
                      <Text style={styles.inputerror}>This last name is required</Text>
                    ) : null}
                  </View>
                  <View style={{ flexDirection: "column", marginRight: 10, width: "20%" }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      Middle Name
                    </Text>
                    <Controller
                      control={control}
                      rules={{}}
                      name="legal_middle_name"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          style={{
                            width: "100%",
                            height: 40,
                            borderColor: "#bdbdbd",
                            borderRadius: 5,
                            borderWidth: 1,
                            marginTop: 10,
                            paddingHorizontal: 10,
                          }}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: "column", marginRight: 10, width: "40%" }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      Last Name <Text style={{ color: "#df0101" }}>*</Text>
                    </Text>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      name="legal_last_name"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          style={{
                            width: "100%",
                            height: 40,
                            borderColor: "#bdbdbd",
                            borderRadius: 5,
                            borderWidth: 1,
                            marginTop: 10,
                            paddingHorizontal: 10,
                          }}
                        />
                      )}
                    />
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 10 }}>
                  <View
                    style={{
                      flexDirection: "column",
                      marginRight: 20,
                      width: "50%",
                      paddingLeft: 10,
                    }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      Email <Text style={{ color: "#df0101" }}>*</Text>
                    </Text>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: "Invalid Email Format",
                        },
                      }}
                      name="email"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          style={{
                            width: "100%",
                            height: 40,
                            borderColor: "#bdbdbd",
                            borderRadius: 5,
                            borderWidth: 1,
                            marginTop: 10,
                            paddingHorizontal: 10,
                          }}
                        />
                      )}
                    />
                    {errors.email?.type === "required" ? (
                      <Text style={styles.inputerror}>This field is required</Text>
                    ) : errors.email?.type === "pattern" ? (
                      <Text style={styles.inputerror}>Invalid Email Format</Text>
                    ) : null}
                  </View>
                  <View style={{ flexDirection: "column", marginRight: 10, width: "50%" }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      Phone Number <Text style={{ color: "#df0101" }}>*</Text>
                    </Text>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        validate: (value) => PhoneNumberFun(value),
                      }}
                      name="phone"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <PhoneInput
                          smartCaret={false}
                          /* displayInitialValueAsLocalNumber */
                          style={{
                            width: "100%",
                            height: 40,
                            border: "solid",
                            borderColor: "#bdbdbd",
                            borderRadius: 5,
                            borderWidth: 1,
                            marginTop: 10,
                            paddingHorizontal: 10,
                          }}
                          placeholder="Enter phone number"
                          onChange={(val) => {
                            onChange(val);
                            setValuePhone(val);
                          }}
                          /*  inputComponent={myPhoneInput} */
                          value={valuePhone}
                        />
                      )}
                    />

                    {errors.phone?.type === "required" ? (
                      <Text style={styles.inputerror}>This field is required</Text>
                    ) : errors.phone?.type === "validate" ? (
                      <Text style={styles.inputerror}>Invalid phone number</Text>
                    ) : null}
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 10 }}>
                  <View
                    style={{
                      flexDirection: "column",
                      marginRight: 10,
                      width: "50%",
                      paddingLeft: 10,
                    }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      Country of citizenship
                    </Text>
                    <Controller
                      control={control}
                      rules={{}}
                      name="citizenship_country"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          value={defaultValueCountryOfCitizenship}
                          className="basic-single"
                          classNamePrefix="select"
                          onChange={(val) => {
                            onChange(val.value);
                            setDefaultValueCountryOfCitizenship(val);
                          }}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          name="color"
                          components={{ Option: CustomOption }}
                          options={options}
                        />
                      )}
                    />
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 10 }}>
                  <View
                    style={{
                      flexDirection: "column",
                      marginRight: 10,
                      width: "50%",
                      paddingLeft: 10,
                    }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      Street (line 1)
                    </Text>
                    <Controller
                      control={control}
                      rules={{}}
                      name="street_line1"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          style={{
                            width: "100%",
                            height: 40,
                            borderColor: "#bdbdbd",
                            borderRadius: 5,
                            borderWidth: 1,
                            marginTop: 10,
                            paddingHorizontal: 10,
                          }}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: "column", marginRight: 10, width: "50%" }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      Street (line 2)
                    </Text>
                    <Controller
                      control={control}
                      rules={{}}
                      name="street_line2"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          style={{
                            width: "100%",
                            height: 40,
                            borderColor: "#bdbdbd",
                            borderRadius: 5,
                            borderWidth: 1,
                            marginTop: 10,
                            paddingHorizontal: 10,
                          }}
                        />
                      )}
                    />
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 10 }}>
                  <View
                    style={{
                      flexDirection: "column",
                      marginRight: 10,
                      width: "50%",
                      paddingLeft: 10,
                    }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      City
                    </Text>
                    <Controller
                      control={control}
                      rules={{}}
                      name="city"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          style={{
                            width: "100%",
                            height: 40,
                            borderColor: "#bdbdbd",
                            borderRadius: 5,
                            borderWidth: 1,
                            marginTop: 10,
                            paddingHorizontal: 10,
                          }}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: "column", marginRight: 10, width: "50%" }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      State/Province
                    </Text>
                    <Controller
                      control={control}
                      rules={{}}
                      name="state"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          style={{
                            width: "100%",
                            height: 40,
                            borderColor: "#bdbdbd",
                            borderRadius: 5,
                            borderWidth: 1,
                            marginTop: 10,
                            paddingHorizontal: 10,
                          }}
                        />
                      )}
                    />
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 10 }}>
                  <View
                    style={{
                      flexDirection: "column",
                      marginRight: 10,
                      width: "50%",
                      paddingLeft: 10,
                    }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      Postal Code
                    </Text>
                    <Controller
                      control={control}
                      rules={{}}
                      name="zipcode"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          style={{
                            width: "100%",
                            height: 40,
                            borderColor: "#bdbdbd",
                            borderRadius: 5,
                            borderWidth: 1,
                            marginTop: 10,
                            paddingHorizontal: 10,
                          }}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: "column", marginRight: 10, width: "50%" }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      Country
                    </Text>
                    <Controller
                      control={control}
                      rules={{}}
                      name="country"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          value={defaultValueCountry}
                          className="basic-single"
                          classNamePrefix="select"
                          onChange={(val) => {
                            onChange(val.value);
                            setDefaultValueCountry(val);
                          }}
                          menuPortalTarget={document.body}
                          menuPosition="fixed"
                          styles={customStyles}
                          name="color"
                          components={{ Option: CustomOption }}
                          options={options}
                        />
                      )}
                    />
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 1 }}>
                <View
        style={{
          backgroundColor: "#fff",
          borderRadius: 15,
          paddingLeft: 11,
          zIndex: 9,
          marginTop: 5,
        }}>
         <Text
                style={{
                  fontFamily: "Roboto_500Medium",
                  fontSize: ismobile ? 11 : 14,
                  color: "#333",
                  marginTop: 10,
                }}>
                     
          Investor Type
        </Text>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name="investor_type"
          render={({ field: { onChange, onBlur, value } }) => (
            <RadioGroup
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: 16,
                    flexDirection: "column",
                    paddingLeft: 10,
                  }}
                  selectedIndex={value}
                  /* value={reqc} */
                  onChange={(e) => {
                    onChange(e); // method from hook form register
                    setinvtype(e); // your method
                  }}
                  onBlur={onBlur}>
                  <Radio status="success">
                Accredited investor{" "}
                <Text
                  onPress={() => {
                    InvestorTypeFun(1);
                  }}>
                  <Icon name="question-mark-circle" fill="#6c757d" width={16} height={16} />
                </Text>
              </Radio>
              <Radio status="success">
                Friends, family and business associate{" "}
                <Text
                  onPress={() => {
                    InvestorTypeFun(2);
                  }}>
                  <Icon name="question-mark-circle" fill="#6c757d" width={16} height={16} />
                </Text>
              </Radio>
              <Radio status="success">
                Others{" "}
                <Text
                  onPress={() => {
                    InvestorTypeFun(3);
                  }}>
                  <Icon name="question-mark-circle" fill="#6c757d" width={16} height={16} />
                </Text>
              </Radio>
                </RadioGroup>
            
          )}
        />
      </View>
                </View>
              </>
            )}
            {subtype === 1 && (
              <>
                <View style={{ flexDirection: "row", marginTop: 20 }}>
                  <View
                    style={{
                      flexDirection: "column",
                      marginRight: 10,
                      width: "50%",
                      paddingLeft: 10,
                    }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      Organization Name <Text style={{ color: "#df0101" }}>*</Text>
                    </Text>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      name="organization_name"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          value={value}
                          onChangeText={onChange}
                          onBlur={onBlur}
                          style={{
                            width: "100%",
                            height: 40,
                            borderColor: "#bdbdbd",
                            borderRadius: 5,
                            borderWidth: 1,
                            marginTop: 10,
                            paddingHorizontal: 10,
                          }}
                        />
                      )}
                    />
                    {errors.organization_name?.type === "required" ? (
                      <Text style={styles.inputerror}>This field is required</Text>
                    ) : null}
                  </View>
                  <View style={{ flexDirection: "column", marginRight: 10, width: "50%" }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      Corporation Number <Text style={{ color: "#df0101" }}>*</Text>
                    </Text>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      name="tax_id"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          value={value}
                          onChangeText={onChange}
                          onBlur={onBlur}
                          style={{
                            width: "100%",
                            height: 40,
                            borderColor: "#bdbdbd",
                            borderRadius: 5,
                            borderWidth: 1,
                            marginTop: 10,
                            paddingHorizontal: 10,
                          }}
                        />
                      )}
                    />
                    {errors.tax_id?.type === "required" ? (
                      <Text style={styles.inputerror}>This field is required</Text>
                    ) : null}
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 10 }}>
                  <View
                    style={{
                      flexDirection: "column",
                      marginRight: 20,
                      width: "50%",
                      paddingLeft: 10,
                    }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      Email <Text style={{ color: "#df0101" }}>*</Text>
                    </Text>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: "Invalid Email Format",
                        },
                      }}
                      name="emailOrganization"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          style={{
                            width: "100%",
                            height: 40,
                            borderColor: "#bdbdbd",
                            borderRadius: 5,
                            borderWidth: 1,
                            marginTop: 10,
                            paddingHorizontal: 10,
                          }}
                        />
                      )}
                    />
                    {errors.emailOrganization?.type === "required" ? (
                      <Text style={styles.inputerror}>This field is required</Text>
                    ) : errors.emailOrganization?.type === "pattern" ? (
                      <Text style={styles.inputerror}>Invalid Email Format</Text>
                    ) : null}
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 10 }}>
                  <View
                    style={{
                      flexDirection: "column",
                      marginRight: 10,
                      width: "50%",
                      paddingLeft: 10,
                    }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      Street (line 1)
                    </Text>
                    <Controller
                      control={control}
                      rules={{}}
                      name="street_line1"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          style={{
                            width: "100%",
                            height: 40,
                            borderColor: "#bdbdbd",
                            borderRadius: 5,
                            borderWidth: 1,
                            marginTop: 10,
                            paddingHorizontal: 10,
                          }}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: "column", marginRight: 10, width: "50%" }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      Street (line 2)
                    </Text>
                    <Controller
                      control={control}
                      rules={{}}
                      name="street_line2"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          style={{
                            width: "100%",
                            height: 40,
                            borderColor: "#bdbdbd",
                            borderRadius: 5,
                            borderWidth: 1,
                            marginTop: 10,
                            paddingHorizontal: 10,
                          }}
                        />
                      )}
                    />
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 10 }}>
                  <View
                    style={{
                      flexDirection: "column",
                      marginRight: 10,
                      width: "50%",
                      paddingLeft: 10,
                    }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      City
                    </Text>
                    <Controller
                      control={control}
                      rules={{}}
                      name="city"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          style={{
                            width: "100%",
                            height: 40,
                            borderColor: "#bdbdbd",
                            borderRadius: 5,
                            borderWidth: 1,
                            marginTop: 10,
                            paddingHorizontal: 10,
                          }}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: "column", marginRight: 10, width: "50%" }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      State/Province
                    </Text>
                    <Controller
                      control={control}
                      rules={{}}
                      name="state"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          style={{
                            width: "100%",
                            height: 40,
                            borderColor: "#bdbdbd",
                            borderRadius: 5,
                            borderWidth: 1,
                            marginTop: 10,
                            paddingHorizontal: 10,
                          }}
                        />
                      )}
                    />
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 10 }}>
                  <View
                    style={{
                      flexDirection: "column",
                      marginRight: 10,
                      width: "50%",
                      paddingLeft: 10,
                    }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      Postal Code
                    </Text>
                    <Controller
                      control={control}
                      rules={{}}
                      name="zipcode"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          style={{
                            width: "100%",
                            height: 40,
                            borderColor: "#bdbdbd",
                            borderRadius: 5,
                            borderWidth: 1,
                            marginTop: 10,
                            paddingHorizontal: 10,
                          }}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: "column", marginRight: 10, width: "50%" }}>
                    <Text
                      style={{
                        fontFamily: "Roboto_500Medium",
                        fontSize: ismobile ? 11 : 14,
                        color: "#333",
                        marginTop: 10,
                      }}>
                      Country
                    </Text>
                    <Controller
                      control={control}
                      rules={{}}
                      name="country"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          value={defaultValueCountry}
                          className="basic-single"
                          classNamePrefix="select"
                          onChange={(val) => {
                            onChange(val.value);
                            setDefaultValueCountry(val);
                          }}
                          menuPortalTarget={document.body}
                          menuPosition="fixed"
                          styles={customStyles}
                          name="color"
                          components={{ Option: CustomOption }}
                          options={options}
                        />
                      )}
                    />
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 1 }}>
                <View
        style={{
          backgroundColor: "#fff",
          borderRadius: 15,
          paddingLeft: 11,
          zIndex: 9,
          marginTop: 5,
        }}>
         <Text
                style={{
                  fontFamily: "Roboto_500Medium",
                  fontSize: ismobile ? 11 : 14,
                  color: "#333",
                  marginTop: 10,
                }}>
                     
          Investor Type
        </Text>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name="investor_type"
          render={({ field: { onChange, onBlur, value } }) => (
            <RadioGroup
                  style={{
                    fontFamily: "Roboto_500Medium",
                    fontSize: 16,
                    flexDirection: "column",
                    paddingLeft: 10,
                  }}
                  selectedIndex={value}
                  /* value={reqc} */
                  onChange={(e) => {
                    onChange(e); // method from hook form register
                    setinvtype(e); // your method
                  }}
                  onBlur={onBlur}>
                  <Radio status="success">
                Accredited investor{" "}
                <Text
                  onPress={() => {
                    InvestorTypeFun(1);
                  }}>
                  <Icon name="question-mark-circle" fill="#6c757d" width={16} height={16} />
                </Text>
              </Radio>
              <Radio status="success">
                Friends, family and business associate{" "}
                <Text
                  onPress={() => {
                    InvestorTypeFun(2);
                  }}>
                  <Icon name="question-mark-circle" fill="#6c757d" width={16} height={16} />
                </Text>
              </Radio>
              <Radio status="success">
                Others{" "}
                <Text
                  onPress={() => {
                    InvestorTypeFun(3);
                  }}>
                  <Icon name="question-mark-circle" fill="#6c757d" width={16} height={16} />
                </Text>
              </Radio>
                </RadioGroup>
            
          )}
        />
      </View>
                </View>
              </>
            )}
          </View>
          <View
            style={{
         
              width: "100%",
              alignSelf: "center",
              flexDirection: "row",
              justifyContent: "flex-end",
              borderRadius: 10,
              marginVertical: 20,
            }}>
              <Button
              style={{
                backgroundColor:"#969696",
                borderColor:"#969696",
                marginHorizontal: 15,
              }}
              status="success"
              onPress={() => {
                setVisible(false);
              }}>
              Cancel
            </Button>
            <Button
              status="success"
              onPress={handleSubmit(onSubmit)}>
              Send request
            </Button>
          </View>
        </KittenCard>
      </Modal>
      <Modal
        style={{
          width: ismobile ? "100%" : "700px",
          maxWidth: 800,
          position: "absolute",
          top: "63%",
        }}
        visible={visibleM}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => setVisibleM(false)}>
        <KittenCard disabled={false}  style={{
            maxWidth: 690,
            minWidth: 290,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            position: "fixed",
            left: ismobile ? "0%" : "50%",
            transform: ismobile ? "translate(-0%,-50%)" : "translate(-50%,-50%)",
            top: "50%",
          }}>
            <View style={{flexDirection:'row', flexWrap:'wrap'}}>
    
          <Text style={{ fontFamily: "Roboto_700Bold", fontSize: 20, color: "#8dc641",marginVertical: 10,flex:0.9 }}>
            {InvestorTypeTitle}
          </Text>
          <View style={{
              
              flex:0.1
            }}>
                  <TouchableOpacity onPress={() => setVisibleM(false)} size={"small"} appearance="ghost" style={{width:10,alignSelf: 'flex-end'}}> <Icon name="close-outline" width={20} height={20} style={{top:0}} fill="#aaa" />
</TouchableOpacity>
                  </View>
    </View>
           
    <View style={{
           marginLeft:15,
            }}>
          <RenderHTML
            source={{ html: `${InvestorTypeDesc}` }}
            style={{
              fontFamily: "Roboto_700Bold",
              fontSize: "15px",
              textAlign: "justify",
              color: "#4f5050",
            
            }}
          />
</View>
          <View
            style={{
              width: "100%",
              alignSelf: "center",
              flexDirection: "row",
              justifyContent: "flex-end",
              borderRadius: 10,
              marginVertical: 20,
            }}>
            <Button
              onPress={() => setVisibleM(false)}
              style={{ marginRight: 10, backgroundColor: "#8dc641", borderColor: "#8dc641" }}>
              OK
            </Button>
          </View>
        </KittenCard>
      </Modal>
    </ScrollView>]
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#f3f4f6",
    alignItems: "center",
    justifyContent: "center",
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  inputerror: {
    fontFamily: "Roboto_400Regular",
    fontSize: 12,
    color: "#f00",
    paddingLeft: 10,
    paddingTop: 10,
  },
});
export default InvestmentsPage;

function NoDocs() {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        backgroundColor: "#f7f8fa",
      }}>
      <Text style={{ fontSize: 20, fontFamily: "Roboto_700Bold", color: "#909398" }}>
        You have no active offerings
      </Text>
      <Text style={{ fontSize: 20, fontFamily: "Roboto_700Bold", color: "#909398", marginTop: 5 }}>
        If your account manager has invited you to any active offerings, they will appear here
      </Text>
    </View>
  );
}
